import eproaccesspage from "@content/guides/images/en/epro-access-page.png";
import eproform from "@content/guides/images/en/epro-form.png";
import epronotyetavailable from "@content/guides/images/en/not-yet-available.png";
import epronomoreavailable from "@content/guides/images/en/no-more-available.png";

import { PageLayout, Section, SubSection, AppIcon } from "@components";

import "@styles/Page.scss";

export default function GuideEproPatient() {
  return (
    <PageLayout
      title="Patient User Guide for ePRO Data Entry"
      titleIcon={<AppIcon name="ConnectedTv" />}
      titleClassName="guide"
      lang="en"
    >
      <Section id="epro-patient-intro" title="Introduction">
        <p>
          You have been invited to participate in a clinical study, and as part
          of this, you are asked to fill out forms remotely. This guide is here
          to help you understand how to use this module. The instructions
          provided here are general, but if you have specific questions about
          the study you are participating in (for example, if you are unsure
          what is expected for a particular question), please contact the
          investigational center managing your participation in the study.
        </p>
      </Section>
      <Section id="epro-patient-general" title="General Overview">
        <p>
          In certain clinical studies, patients are asked to fill out forms
          directly from their computer, tablet, or mobile phone. This allows you
          to contribute from home without needing to travel to a center.
        </p>
        <SubSection title="Receiving Invitation Emails">
          <p>
            Depending on the study, you may be asked to fill out one or more
            forms, sometimes on a regular basis (for example, one week after an
            exam, and then three months later). For each form that you need to
            complete, you will receive a separate email. Each email contains
            technical information such as the study name and the name of the
            form to be filled out, along with a button that links you to the
            form. The link is unique to each form and cannot be used for any
            other form.
          </p>
          <p>
            The forms have a validity period defined by the study organizer
            (sponsor). You will receive an invitation email on the first day of
            the validity period. A reminder email will be sent every seven days
            until the form is completed or the form is no longer valid. Emails
            are generally sent in the morning around 10 a.m. (Paris time).
          </p>
          <p>
            <b>Note:</b> If you need to fill out multiple forms at the same
            time, you will receive multiple invitation emails. Be sure to
            complete all forms.
          </p>
        </SubSection>
        <SubSection title="Logging into the Platform">
          <p>
            The invitation email contains a link that redirects you to a login
            page on the Dotter.science website. This platform has been chosen by
            the study organizer (sponsor) to manage the data for the clinical
            study.
          </p>
          <img
            src={eproaccesspage}
            alt="Page d'accès ePRO (patient)"
            className="centered"
          />
          <p>
            The link will direct you to a login page where you will be asked for
            a code to verify your identity. If you encounter an error message or
            technical issue, refer to the "Troubleshooting" section below.
          </p>
          <p>
            The access code was provided to you by your investigational center.
            In some cases, the sponsor may use a code based on personal
            information (for example, your year of birth), and instructions will
            guide you on what to enter. If you experience difficulties, refer to
            the "Troubleshooting" section below.
          </p>
        </SubSection>
        <SubSection title="Entering Information">
          <p>
            Once your access code is verified, you will be directed to the form
            that needs to be completed.
          </p>
          <img src={eproform} alt="Formulaire ePRO" className="centered" />
          <p>
            Fill in the required fields as accurately as possible. If you have
            any questions about how to enter the data, consult the documentation
            provided by the investigational center or contact them for
            assistance.
          </p>
          <p>
            You can save the form at any time. Therefore, it is not necessary to
            fully complete the form before saving.{" "}
            <b>We recommend saving the form regularly</b> to minimize the risk
            of losing your progress. If the page is closed (for example, due to
            an accidental action or a power outage), any unsaved data will be
            lost, and you will need to start again.
          </p>
          <p>
            Once the form is saved, you can close the page and return later. The
            saved data will be retrieved when you return.
          </p>
        </SubSection>
      </Section>
      <Section id="epro-patient-troubleshooting" title="Troubleshooting">
        <SubSection title="I Am Not Receiving Invitation Emails">
          <p>
            If you believe you are not receiving the invitation emails, follow
            these steps:
            <ol>
              <li>
                Check your email inbox to see if they were mistakenly marked as
                junk and moved to a "junk mail" or "spam" folder. Note that
                depending on your email provider's settings, emails marked as
                spam may be automatically deleted after a few days (for example,
                30 days with Gmail).
              </li>
              <li>
                If you still cannot find the invitation email, contact the
                investigational center that is monitoring you for this study.
                You should have received an information document when you signed
                your consent to participate in the study.
              </li>
            </ol>
          </p>
        </SubSection>
        <SubSection title="Error Message at the Login Page">
          <p>
            If, when arriving at the login page, you see a{" "}
            <b>message indicating that the form is no longer valid</b>, it means
            you have missed the submission deadline. Contact your
            investigational center to determine if the submission period can be
            extended.
          </p>
          <img
            src={epronomoreavailable}
            alt="Le formulair n'est plus disponible"
            className="centered"
          />
          <p>
            If, when arriving at the login page, you see a{" "}
            <b>message indicating that the form is not yet valid</b>, it is
            possible that the start date of validity has been changed after the
            email was sent. Contact your investigational center for more
            information.
          </p>
          <img
            src={epronotyetavailable}
            alt="Le formulair n'est pas encore disponible"
            className="centered"
          />
          <p>
            If you see{" "}
            <b>another message preventing you from accessing the login page</b>,
            you should contact your investigational center and describe the
            problem as clearly as possible. To help identify the issue, and if
            you have the necessary technical skills, take a screenshot to
            capture the image of your screen in a file.
          </p>
        </SubSection>
        <SubSection title="Technical Issues Preventing Login or Data Entry">
          <p>
            If, when arriving at the login page,{" "}
            <b>nothing is displayed (no content, no error message)</b>, make
            sure that your internet connection is working properly (check if you
            can browse other websites without difficulty). If you do not have a
            connection problem, contact your investigational center and describe
            your issue as clearly as possible.
          </p>
          <p>
            If the login page displays correctly but you see an error message
            indicating an <b>authentication problem</b> (the message says that
            the code is invalid), verify that you have entered it correctly,
            then check your internet connection again. If everything appears to
            be in order, contact your investigational center and describe your
            issue clearly.
          </p>
          <p>
            If you manage to log in but <b>cannot save the form</b> (an error
            message appears during saving), do not insist and contact your
            investigational center as soon as possible. If possible, keep your
            web page open, as it may only be a temporary issue lasting a few
            minutes, allowing you to save your progress later.
          </p>
        </SubSection>
        <SubSection title="Can I Contact You Directly If I Have a Problem?">
          <p>
            No, it is best to contact your investigational center if you have
            difficulties. The people in charge of your study follow-up will be
            better able to assist you based on your needs and the specificities
            of the study.
          </p>
          <p>
            To help process your request, provide as much information as
            possible about your difficulty. If the issue is purely technical,
            the investigational centers will contact us directly and will get
            back to you if necessary (it could be an issue that is already being
            resolved). In very rare cases, if we are unable to understand the
            problem, and with the sponsor's and investigational center's
            agreement, we may contact you directly.
          </p>
        </SubSection>
      </Section>
    </PageLayout>
  );
}
