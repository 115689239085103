import queries from "@content/tutorial/images/Add notes-amico.svg";
import openquery from "@content/advanced/images/fr/openquery.png";
import redigerquery from "@content/advanced/images/fr/redigerquery.png";
import accesqueries from "@content/advanced/images/fr/acces-queries.png";
import filtres from "@content/tutorial/images/fr/filtres-queries.png";

import { Note, Section } from "@components";

import { UsingDotterLayout } from "@content/tutorial/using-dotter/Layout";

export default function TutorialUsingDotterStep4() {
  return (
    <UsingDotterLayout idx={4} lang="fr" heroUrl={queries} heroAlt="Queries">
      <Section title="Ouvrir une query">
        <p>
          Les <b>queries</b> sont des notes qui peuvent être ajoutées
          manuellement sur n'importe quel entrée de l'eCRF et listées dans un
          tableau que vous pourrez consulter par la suite pour en avoir une vue
          d'ensemble.
        </p>
        <p>
          Pour ouvrir une query, survolez le champ de l'eCRF souhaité, une bulle
          de dialogue apparaît alors à sa droite. Cliquez sur la bulle pour que
          l'édition de query s'ouvre.
        </p>
        <img src={openquery} alt="Ajout d'une query'" className="centered" />
        <p>
          Rédigez votre query puis cliquez sur "<b>Envoyer</b>"
        </p>
        <img src={redigerquery} alt="Rédiger une query" className="centered" />
      </Section>
      <Section title="Consulter les queries">
        <p>
          Pour avoir une vue d'ensemble de toutes les queries de l'étude,
          rendez-vous dans le menu <b>Live</b> puis l'onglet{" "}
          <b>Liste des queries</b>.
        </p>
        <img src={accesqueries} alt="Onglet queries" className="centered" />
        <p>Cliquez sur n'importe quelle query pour consulter son contenu.</p>
        <Note type="tip">
          Pour faciliter la recherche de certaines queries particulières, vous
          pouvez filtrer les queries en cliquant sur le bouton "
          <b>Ajouter un filtre</b>" en haut à droite de l'écran. Vous pourrez
          ainsi les trier en fonction de différents critères (état, centre
          émetteur, date).
          <img src={filtres} alt="Filtres des queries" className="centered" />
        </Note>
      </Section>
    </UsingDotterLayout>
  );
}
