import acceseditionetude from "@content/advanced/images/fr/acces-edition-etude.png";
import modeeditionmodele from "@content/advanced/images/fr/modeeditionmodele.png";
import creervariable from "@content/advanced/images/fr/creervariable.png";
import typevariable from "@content/advanced/images/fr/typevariable.png";
import exemplevariable from "@content/advanced/images/fr/exemple-variable.png";
import paramsavances from "@content/advanced/images/fr/variableadvancedsettings.png";
import alertes from "@content/advanced/images/fr/alertes.png";
import editervariable from "@content/advanced/images/fr/editvariable.png";

import {
  PageLayout,
  Section,
  SubSection,
  Note,
  ResponsiveTable,
} from "@components";

import "@styles/Page.scss";

export default function FeatVariables() {
  return (
    <PageLayout title="Les variables" roles={["admin"]} lang="fr">
      <Section
        id="accéder-à-l-édition-des-variables"
        title="Accéder à l'édition des variables"
      >
        <p>
          Rendez-vous dans la partie <b>Conception</b> puis{" "}
          <b>Édition de l'étude</b>.
        </p>
        <img
          src={acceseditionetude}
          alt="Onglet édition de l'étude"
          className="centered"
        />
        <p>
          Passez l'interface en mode <b>Édition des modèles</b> en cliquant sur
          l'interrupteur qui se trouve en haut à droite de votre écran.
        </p>
        <img
          src={modeeditionmodele}
          alt="Passer en mode édition des modèles"
          className="centered"
        />
      </Section>
      <Section id="créer-une-variable" title="Créer une variable">
        <p>
          Il y a deux manières différentes d'ajouter des variables dans
          Dotter.science :
          <li>
            Rendez-vous dans la partie <b>Variables</b> (1) puis cliquez sur le{" "}
            <b>bouton +</b> (2)
          </li>
          <li>
            Survolez votre section puis cliquez sur le bouton{" "}
            <b>Ajouter une nouvelle variable</b> (3) qui va apparaître.
          </li>
        </p>
        <img
          src={creervariable}
          alt="Créer une variable"
          className="centered"
        />
        <p>Choisissez le type de variable que vous souhaitez configurer.</p>
        <img src={typevariable} alt="Types de variable" className="centered" />
        <p>Il existe 8 types de variables possibles.</p>
        <ResponsiveTable>
          <tr>
            <td>Type de variable</td>
            <td>Description</td>
          </tr>
          <tr>
            <td>Date ou heure</td>
            <td>Permet d'enregistrer une date et/ou une heure.</td>
          </tr>
          <tr>
            <td>Mesure</td>
            <td>
              Permet d'enregistrer un nombre sous une certaine unité{" "}
              <em>(exemple: une masse en kg)</em>, supporte la conversion
              <em>(exemple: kilogrammes vers grammes)</em>.
            </td>
          </tr>
          <tr>
            <td>Champ texte</td>
            <td>Permet d'enregistrerun court texte ou un paragraphe.</td>
          </tr>
          <tr>
            <td>Booléen</td>
            <td>
              Permet d'enregistrer une variable binaire qui aura pour valeur "
              <b>Vrai</b>" ou "<b>Faux</b>".
            </td>
          </tr>
          <tr>
            <td>Choix unique</td>
            <td>
              Affiche une liste d'options parmi lesquelles l'utilisateur ne
              pourra en sélectionner qu'<b>une seule</b>.
            </td>
          </tr>
          <tr>
            <td>Choix multiple</td>
            <td>
              Affiche une liste d'options parmi lesquelles l'utilisateur pourra
              en cocher <b>plusieurs</b>.
            </td>
          </tr>
          <tr>
            <td>Calcul</td>
            <td>
              Utilise des variables déjà existantes dans votre étude afin d'en
              calculer une nouvelle valeur{" "}
              <em>
                (exemple : calculer un IMC à partir d'un poids et d'une taille
                déjà renseignées)
              </em>
            </td>
          </tr>
          <tr>
            <td>Contenu statique</td>
            <td>
              Affiche un bloc de texte non modifiable par l'utilisateur{" "}
              <em>
                (exemple : donner des instructions spécifiques au formulaire)
              </em>
              .
            </td>
          </tr>
        </ResponsiveTable>
        <p>
          Une nouvelle fenêtre apparaît alors afin de configurer la nouvelle
          variable.
        </p>
      </Section>
      <Section id="configurer-une-variable" title="Configurer une variable">
        <SubSection title="Paramètres généraux">
          <img
            src={exemplevariable}
            alt="Paramètres généraux d'une variable"
            className="centered"
          />
          <p>
            La fenêtre contextuelle permet de paramétrer la variable, en
            renseignant les champs suivants :
            <li>
              <b>Nom de la variable</b> : telle qu'elle apparaîtra dans la base
              de données (nom simple ou raccourci)
            </li>
            <li>
              <b>Label de la variable</b> : telle qu'elle est visible sur la
              plateforme (nom complet)
            </li>
            <li>
              <b>Notice</b> : aide affichée au survol lors du remplissage du
              formulaire
            </li>
            <li>
              Autorisations des valeurs <b>Non applicable (NA)</b> ou{" "}
              <b>Données manquantes (DM)</b>
            </li>
            Les autres champs disponibles dépendent du type de variable créée.
          </p>
        </SubSection>
        <SubSection title="Définir des alertes">
          <p>
            Il est possible de définir des conditions qui affichent par exemple
            une alerte si une certaine valeur est trop élevée pour notre
            variable, cette alerte sera présente dans l'eCRF.
          </p>
          <p>
            Dans le menu d'édition de votre variable, rendez-vous dans{" "}
            <b>Paramètres avancés</b>.
          </p>
          <img
            src={paramsavances}
            alt="Paramètres avancés"
            className="centered"
          />
          <p>
            Vous avez le choix entre deux types d'alertes différentes :
            <li>
              Les <b>avertissements</b> (1) : alertes de bas niveau ignorables
              par un clic sur l'icône
            </li>
            <li>
              Les <b>erreurs critiques</b> (2) : alertes de haut niveau qui
              devront être traitées pour permettre le verrouillage des
              formulaires
            </li>
          </p>
          <img src={alertes} alt="Types d'alertes" className="centered" />
          <Note type="note">
            L'enregistrement des formulaires n'est jamais bloqué, même en
            présence d'alertes.
          </Note>
        </SubSection>
      </Section>
      <Section
        id="éditer-une-variable-existante"
        title="Éditer une variable existante"
      >
        <p>
          Pour accéder à l'édition d'une variable déjà existante, deux
          possibilités :
          <li>
            La variable est utilisée dans le modèle que vous éditez, vous pouvez
            cliquer sur le <b>bouton de menu</b> (1) et sélectionner{" "}
            <b>Éditer</b> (2)
          </li>
          <li>
            Vous pouvez cliquer direcement sur la <b>variable</b> (3) dans la
            liste des éléments, à gauche de l'écran.
          </li>
        </p>
        <img
          src={editervariable}
          alt="Éditer une variable"
          className="centered"
        />
      </Section>
    </PageLayout>
  );
}
