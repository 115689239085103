import acces from "@content/guides/images/en/my-profile-menu.png";
import mdp from "@content/guides/images/en/change-password.png";
import lang from "@content/guides/images/en/language-preference.png";
import version from "@content/guides/images/en/version.png";

import { ManageAccounts } from "@mui/icons-material";

import { PageLayout, Section, Note } from "@components";

import "@styles/Page.scss";

export default function GuideProfile() {
  return (
    <PageLayout
      title="Profile Management"
      titleIcon={<ManageAccounts />}
      titleClassName="guide"
      lang="en"
    >
      <p>
        The purpose of this guide is to show you how to access and modify your
        personal information as a Dotter.science user.
      </p>
      <Section id="accessing-user-profile" title="Accessing the User Profile">
        <p>
          At the top of the main menu, click on <b>your name</b> to open the
          submenus and click on the <b>My Profile</b> tab.
        </p>
        <img src={acces} alt="My Profile Tab" className="centered" />
      </Section>
      <Section
        id="modifying-my-personal-information"
        title="Modifying My Personal Information"
      >
        <p>
          Upon your first login and in the event of significant changes, it is
          recommended to complete the information related to your profile. The
          personal information is displayed in the first block of the page:
          <li>
            <b>Email Address</b>
          </li>
          <li>
            <b>First Name</b>
          </li>
          <li>
            <b>Last Name</b>
          </li>
          <li>Address</li>
          <li>Postal Code</li>
          <li>City</li>
          <li>Country</li>
          <li>Phone</li>
          <li>Fax</li>
          <li>Institution</li>
          <li>Company</li>
          <br />
          Click on a field to modify it, then click the <b>Modify</b> button at
          the bottom of the block to save your changes.
        </p>
        <Note type="caution">
          It is not possible to modify the <b>email address</b> field; this is
          the address you used for your registration or through which you were
          invited to use Dotter.science. In case you lose your password, this is
          the email address you will need to provide. Therefore, make sure you
          have access to this email address.
        </Note>
      </Section>
      <Section id="modifying-my-password" title="Modifying My Password">
        <p>
          You can change your Dotter.science access password at any time. To do
          this, go to the <b>Password</b> block.
        </p>
        <img src={mdp} alt="Password Block" className="centered" />
        <p>
          Enter your current password and then the new password you wish to set.
          It is recommended to use a password of at least 8 characters
          containing uppercase and lowercase letters, numbers, and special
          characters. Repeat your password and then click <b>Modify</b>. A
          message will confirm that your password has been successfully changed.
        </p>
      </Section>
      <Section
        id="display-language-and-version-of-dotter-science"
        title="Display Language and Version of Dotter.science"
      >
        <p>
          Dotter.science is currently available in French and English. To change
          the display language, go to the <b>Language Preference</b> block and
          click on the <em>Language Selection</em> field. A dropdown menu will
          allow you to switch Dotter.science to <em>French</em>,{" "}
          <em>English</em>, or <em>Automatic Detection</em> (the choice will
          depend on your browser settings). Click <b>Modify</b> to confirm your
          choice.
        </p>
        <img src={lang} alt="Language Preference Block" className="centered" />
        <Note type="note">
          Translations apply only to texts directly related to the platform and
          will not apply to user-entered data such as form responses or center
          names, for example.
        </Note>
        <p>
          Dotter.science is regularly updated and has a growing number of
          versions. You can know the version you are currently using by checking
          the <b>Version</b> block. This information can be useful in case of
          bug reporting or assistance needs.
        </p>
        <img src={version} alt="Version Block" className="centered" />
        <Note type="tip">
          You can also consult the Terms of Use (ToS) from this block by
          clicking on the blue link.
        </Note>
      </Section>
    </PageLayout>
  );
}
