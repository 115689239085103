import switchecrf from "@content/guides/images/fr/switch-ecrf.png";
import eprosettings from "@content/guides/images/fr/epro-settings.png";
import eproinvitsempty from "@content/guides/images/fr/epro-empty-invit-list.png";
import eproinvit from "@content/guides/images/fr/epro-invit-list.png";

import { PageLayout, Section, SubSection, AppIcon } from "@components";

import "@styles/Page.scss";

export default function GuideEproLogin() {
  return (
    <PageLayout
      title="Guide de paramétrage ePRO"
      titleIcon={<AppIcon name="Share" />}
      titleClassName="guide"
      lang="fr"
    >
      <p>
        Le module ePRO de Dotter.science permet aux sujets de remplir des
        formulaires de manière autonome sur leurs propres appareils, facilitant
        ainsi la collecte de données sans intervention manuelle. Cette
        fonctionnalité améliore la qualité des données en minimisant les erreurs
        de transcription. Les équipes de Dotter.science assurent la conception
        et le paramétrage initial pour le promoteur (c'est-à-dire l'organisateur
        de l'étude clinique), incluant la définition des questionnaires et la
        configuration générale de l’étude.
      </p>
      <p>
        En revanche, vous êtes responsable du paramétrage individuel des sujets.
        Ce paramétrage inclut la saisie de l’email du sujet pour l’envoi des
        invitations et la création d’un code d’accès unique pour garantir la
        sécurité des données. Il est essentiel que chaque sujet soit paramétré
        correctement afin qu’il reçoive les invitations en temps voulu et puisse
        remplir les formulaires requis selon le calendrier de l’étude.
      </p>
      <p>
        Chaque sujet reçoit une invitation par email pour chaque formulaire, en
        fonction des dates définies, avec des rappels automatiques si un
        formulaire n’est pas saisi. Vous pouvez modifier ces dates de validité
        et même compléter les formulaires vous-même si les sujets rencontrent
        des difficultés, ou si vous souhaitez les compléter avec eux au
        téléphone.
      </p>
      <p>
        Cette documentation est conçue pour vous guider à travers toutes les
        étapes nécessaires au paramétrage individuel des sujets : accès aux
        réglages, saisie des informations de contact, génération des
        invitations, et suivi de l’état des saisies. Cela garantit que chaque
        sujet est correctement enregistré et que les données sont collectées de
        manière précise et opportune, répondant ainsi aux exigences du protocole
        clinique.
      </p>
      <Section id="epro-fonctionnement-general" title="Fonctionnement général">
        <p>
          Après le paramétrage ePRO pour une étude et un sujet donné, chaque
          sujet recevra un{" "}
          <b>email d'invitation distinct pour chaque formulaire</b>à compléter,
          en fonction des échéances définies dans le calendrier de l'étude.
          Chaque email comprend des informations spécifiques à l'étude, le nom
          du formulaire à remplir, et un lien d'accès sécurisé permettant au
          sujet de saisir les données.
        </p>
        <p>
          Les emails d'invitation sont généralement envoyés le matin vers 10h
          (heure de Paris). Actuellement, ni l'heure d'envoi ni le fuseau
          horaire ne sont ajustables. Si le sujet ne complète pas un formulaire
          ePRO, un rappel est automatiquement envoyé tous les 7 jours jusqu'à ce
          que la saisie soit réalisée ou jusqu'à la date d'expiration du
          formulaire. Ces paramètres ne sont pas modifiables.
        </p>
        <p>
          Si plusieurs formulaires ePRO doivent être remplis le même jour,
          chaque formulaire génère un email distinct, qui sera envoyé
          simultanément. Cela signifie que le sujet pourrait recevoir plusieurs
          invitations le même jour, notamment lorsque plusieurs formulaires ePRO
          ont des dates de validité communes.
        </p>
        <p>
          Vous avez la possibilité de{" "}
          <b>modifier les dates de validité des formulaires</b>. Par exemple,
          cela peut être utile si un sujet rencontre une difficulté technique
          temporaire qui l'empêche de remplir le formulaire dans les délais
          prévus. Cette fonctionnalité permet de maintenir la flexibilité
          nécessaire pour assurer une bonne collecte des données.
        </p>
        <p>
          Les formulaires ePRO sont intégrés dans la trame générale de l'étude,
          aux côtés des autres formulaires non ePRO, et sont marqués par un
          bandeau indiquant qu'ils doivent être complétés par les sujets. En cas
          de difficulté d'un sujet à saisir les données via l'interface web, des
          alternatives telles que la saisie papier ou un appel téléphonique
          peuvent être utilisées, et vous pouvez alors entrer manuellement les
          données dans le système ePRO. Les modifications apportées aux
          formulaires, que ce soit par les sujets ou par le personnel de
          l'étude, sont toujours tracées pour garantir une transparence totale
          sur les données saisies.
        </p>
      </Section>
      <Section id="epro-subject-settings" title="Paramétrage du sujet">
        <SubSection title="Étape 1 : Selectionner le sujet">
          <p>
            Pour commencer, sélectionnez le sujet souhaité en accédant à la
            partie "Dossier patient". Cliquez sur le bouton situé en haut à
            droite du formulaire pour naviguer vers la section correspondante.
          </p>
          <img src={switchecrf} alt="Switch vers eCRF" className="centered" />
        </SubSection>
        <SubSection title="Étape 2 : Accéder au paramétrage ePRO">
          <p>
            Une fois dans le dossier du sujet, cliquez sur le bouton
            "Paramétrage ePRO" qui se trouve sur la gauche de l'écran.
          </p>
          <img src={eprosettings} alt="Paramétrage ePRO" className="centered" />
        </SubSection>
        <SubSection title="Étape 3 : Vérifier les droits d'édition">
          <p>
            Si les champs "email" et "code d'accès sujet" ne sont pas éditables,
            cela signifie que vous n'avez pas les droits nécessaires pour
            consulter et éditer les données sensibles de ce sujet. Ces droits
            sont octroyés par les administrateurs de l'étude. Si vous ne pouvez
            pas accéder à ces informations, veuillez contacter un administrateur
            de l'étude pour obtenir l'autorisation requise.
          </p>
        </SubSection>
        <SubSection title="Étape 4 : Saisir l'adresse email">
          <p>
            Dans le champ "email", saisissez l'adresse fournie par le sujet.
            Assurez-vous que cette adresse est correcte, car il n'y a pas de
            vérification automatique de sa validité avant l'envoi des
            invitations. Une erreur dans l'adresse empêcherait le sujet de
            recevoir les invitations, ce qui pourrait retarder sa participation
            à l'étude.
          </p>
        </SubSection>
        <SubSection title="Étape 5 : Créer le code d'accès du sujet">
          <p>
            Le champ "code d'accès sujet" est libre. Vous pouvez y entrer une
            combinaison de caractères ou de valeurs numériques. Notez que le
            sujet ne recevra pas automatiquement de communication par email avec
            ce code d'accès. Il est de votre responsabilité de transmettre ce
            code au sujet par un autre canal sécurisé. Le promoteur peut
            spécifier des règles particulières pour la construction de ce code,
            comme l'utilisation de l'année de naissance du sujet. Il est
            important de suivre ces recommandations autant que possible, car
            elles facilitent l'accès des sujets à l'étude (par exemple, en leur
            fournissant des indications sur la page de connexion).
          </p>
        </SubSection>
        <SubSection title="Étape 6 : Saisir la date de référence">
          <p>
            Enfin, vous devez saisir une date de référence, qui servira à
            calculer automatiquement les dates de validité des invitations ePRO.
            Cette date peut correspondre, par exemple, à la date d'inclusion
            effective du sujet dans l'étude. Une fois les invitations générées,
            cette date de référence n'aura plus d'incidence sur les dates de
            validité des formulaires.
          </p>
        </SubSection>
      </Section>
      <Section
        id="epro-generate-invitations"
        title="Génération des invitations"
      >
        <SubSection title="Étape 1 : Générer les invitations">
          <p>
            Pour générer les invitations selon le plan établi par
            l'investigateur, cliquez sur le bouton "Générer les invitations". Ce
            bouton est accessible une fois que toutes les informations du sujet
            ont été correctement paramétrées.
          </p>
          <img
            src={eproinvitsempty}
            alt="Générer les invitations"
            className="centered"
          />
          <p>
            <b>Remarque importante :</b> Pour pouvoir générer les invitations,
            le sujet doit être complètement paramétré, inclus et randomisé dans
            l'étude. Assurez-vous que toutes ces conditions sont remplies avant
            de procéder. Une fois ces conditions satisfaites, les invitations
            devraient apparaître dans la section appropriée.
          </p>
          <img
            src={eproinvit}
            alt="Invitations générées"
            className="centered"
          />
        </SubSection>
        <SubSection title="Étape 2 : Modifier les dates de validité">
          <p>
            Après la génération des invitations, les dates de validité des
            formulaires sont automatiquement calculées en fonction des
            paramètres définis pour le module ePRO et de la date de référence du
            sujet. Si nécessaire, ces dates peuvent être ajustées manuellement.
          </p>
          <p>
            Pour modifier une date de validité, cliquez sur les trois points
            situés à la fin de la ligne du formulaire, puis sélectionnez
            "Éditer". Un popup s'ouvrira, vous permettant de modifier la date de
            début et la date de fin de validité des invitations.
          </p>
          <p>
            Ces ajustements peuvent être utiles pour adapter la collecte de
            données aux contraintes spécifiques des sujets, garantissant ainsi
            une flexibilité optimale dans la gestion des échéances.
          </p>
        </SubSection>
      </Section>
      <Section id="epro-followup" title="Suivi de la saisie">
        <p>
          Le suivi de la saisie des formulaires ePRO est facilité par un système
          de code couleur associé à un symbole d'enveloppe. Ce code couleur vous
          permet de déterminer rapidement l'état d'avancement de la saisie des
          formulaires, facilitant ainsi la gestion de la collecte des données et
          la prise de décisions concernant les actions à entreprendre.
        </p>
        <SubSection title="Signification des codes couleurs">
          <ul>
            <li>
              <b>Gris :</b> Le formulaire est <b>non actif</b>. Cela signifie
              que la période de validité n'a pas encore commencé et que le
              formulaire n'est pas encore disponible pour le sujet.
            </li>
            <li>
              <b>Cyan :</b> Le formulaire est <b>actif</b>, mais aucune donnée
              n'a été saisie pour le moment. Le sujet a reçu l'invitation, mais
              n'a pas encore commencé à remplir le formulaire.
            </li>
            <li>
              <b>Jaune :</b> Le formulaire est <b>partiellement complété</b>.
              Cela indique que le sujet a commencé la saisie, mais certains
              champs restent encore à compléter. Une relance manuelle peut être
              nécessaire pour s'assurer que le sujet n'a rencontré aucun
              problème et pour l'encourager à finaliser le formulaire.
            </li>
            <li>
              <b>Vert :</b> Le formulaire est <b>complètement saisi</b>. Tous
              les champs ont été remplis avec succès, et aucune action
              supplémentaire n'est requise.
            </li>
            <li>
              <b>Rouge :</b> Le formulaire est <b>expiré</b> sans avoir été
              entièrement rempli. Cela signifie que la période de validité est
              terminée et que le formulaire n'a pas été complété dans les délais
              impartis.
            </li>
          </ul>
          <p>
            Ce système de suivi visuel permet de gérer efficacement la collecte
            des données, en identifiant rapidement les formulaires qui
            nécessitent une attention particulière, qu'il s'agisse de relancer
            un sujet ou de vérifier des informations incomplètes.
          </p>
        </SubSection>
      </Section>
    </PageLayout>
  );
}
