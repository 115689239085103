import completedsteps from "@content/tutorial/images/Completed steps-amico.svg";
import modeeditionsynopsis from "@content/advanced/images/fr/modeeditionsynopsis.png";
import panneausynopsis from "@content/tutorial/images/fr/panneausynopsis.png";
import editionphase from "@content/tutorial/images/fr/edition-phase.png";
import ajouteretape from "@content/tutorial/images/fr/ajouter-etapes.png";
import modeleassocie from "@content/tutorial/images/fr/modele-associe.png";
import exempleetape from "@content/tutorial/images/fr/exemple-etape.png";

import { Note, Section, SubSection } from "@components";

import { AtoZLayout } from "@content/tutorial/a-to-z/Layout";

export default function TutorialAtoZStep5() {
  return (
    <AtoZLayout
      lang="fr"
      idx={5}
      heroUrl={completedsteps}
      heroAlt="Mise en place de l'eCRF"
    >
      <p>
        Maintenant que tous les formulaires de l'étude sont prêts, le synopsis
        de l'étude peut être réalisé. Cela va consister à placer les formulaires
        dans l'ordre de leur apparition chronologique au cours de l'étude. Pour
        accéder au synopsis de l'étude, restez dans l'onglet{" "}
        <b>Édition de l'étude</b> et basculez en mode <b>Synopsis de l'étude</b>{" "}
        en cliquant sur le bouton en haut à droite de l'écran.
      </p>
      <img
        src={modeeditionsynopsis}
        alt="Mode Synopsis de l'étude"
        className="centered"
      />
      <p>
        La liste à gauche de l'écran présente les différentes phases de l'étude.
        On y retrouve pour l'instant trois éléments :
        <li>
          la phase <b>BASELINE</b> qui est la phase initiale de l'étude. Elle
          pourra par exemple contenir des formulaires d'inclusion, de données
          démographiques ou autres documents d'ordre légal (consentement, etc.)
          <br />
          <em>
            (Note : si cette phase reste vide elle n'apparaîtra pas dans
            l'étude)
          </em>
        </li>
        <li>
          le bouton <b>AJOUTER UNE PHASE</b> qui permettra d'ajouter les phases,
          c'est-à-dire les périodes (temporelles ou non) spécifiques à l'étude
          (par exemple : suivi à 6 mois) et pouvant contenir une ou plusieurs
          étapes auxquelles seront associés les modèles de formulaires.
        </li>
        <li>
          les <b>FORMULAIRES SPÉCIFIQUES</b> qui interviendront hors du flux
          normal de l'étude{" "}
          <em>
            (par exemple des formulaires de sortie d'étude, de signalement
            d'événements indésirable, etc...)
          </em>
        </li>
      </p>
      <img
        src={panneausynopsis}
        alt="Panneau Synopsis de l'étude"
        className="centered"
      />
      <p>
        Comme vu dans le chapitre précédent, sur Dotter.science nous pouvons
        découper chaque phase en étapes. Ici une "étape" intègre un seul modèle
        de formulaire, mais un modèle de formulaire peut être utilisé au sein de
        plusieurs étapes. Le but de ce chapitre est donc d'apprendre comment
        insérer les formulaires dans les phases de l'étude et comment les
        reprogrammer facilement.
      </p>

      <Section title="Créer des phases et des étapes">
        <SubSection title="Créer une phase">
          <p>
            Cliquez tout d'abord sur le bouton <b>Ajouter une phase</b>, la
            deuxième partie de l'écran change pour afficher la configuration de
            cette nouvelle phase.
          </p>
          <img
            src={editionphase}
            alt="Édition d'une phase"
            className=" centered"
          />
          <p>
            Donnez un nom à cette phase en passant le curseur sur le champ "
            <em>Phase sans nom</em>" et en cliquant sur l'icône qui apparaît.
          </p>
          <p>
            Si cette phase doit se répéter dans le temps (tous les jours, toutes
            les semaines, etc...), choisissez une temporalité et modifiez
            l'indice de la phase en fonction de sa position.
          </p>
          <p>
            Si cette phase est répétable, c'est-à-dire qu'elle peut être
            réalisée plusieurs fois dans la même temporalité, cochez la case "
            <b>Phase répétable</b>" et indiquez le nombre de répétitions
            possibles.
          </p>
          <Note type="tip">
            Vous remarquerez que l'icone de la phase change en fonction des
            paramètres que vous lui attribuez. Si vous souhaitez modifier cet
            affichage, choisissez la temporalité <em>Personnalisée</em>. Vous
            pourrez ainsi choisir la lettre qui s'affiche et le sous-texte
            adapté.
          </Note>
          <p>
            Lorsque vous avez terminé de paramétrer la phase, cliquez sur le
            bouton <b>Ajouter</b> ou <b>Modifier</b> qui s'affiche en vert en
            haut de l'écran.
          </p>
        </SubSection>
        <SubSection title="Créer une étape">
          <p>
            Sélectionnez la phase que vous souhaitez compléter en cliquant
            dessus dans le panneau de gauche. Un bouton{" "}
            <b>+ Ajouter une étape</b> apparaît, cliquez dessus.
          </p>
          <img
            src={ajouteretape}
            alt="Ajouter une étape"
            className="centered"
          />
          <p>
            Le deuxième panneau change de nouveau pour afficher l'édition de
            l'étape en cours. Donnez-lui un nom en passant votre curseur sur{" "}
            <em>Étape sans nom</em> et en cliquant sur l'icône qui apparaît.
          </p>
          <p>
            Pour associer un formulaire à cette étape vous pouvez choisir de
            recréer un nouveau formulaire complet, ou bien de récupérer un
            modèle de formulaire déjà créé.
          </p>
          <img
            src={modeleassocie}
            alt="Modèle associé à l'étape"
            className="centered"
          />
          <Note type="note">
            Dans le cadre de ce tutoriel, nous considérons que vous avez déjà
            créé les modèles de formulaires dans le chapitre précédent et nous
            les utiliseront directement ici. Si ce n'est pas le cas, consultez
            le chapitre <b>Conception de l'eCRF</b> qui vous expliquera comment
            créer un modèle de formulaire.
          </Note>
          <p>
            Cliquez sur l'icône "<b>Utiliser un modèle existant</b>", une
            fenêtre s'ouvre pour afficher tous les modèles déjà conçus. Cliquez
            sur celui que vous souhaitez associer à l'étape en cours. Le
            formulaire et son contenu s'affichent aussitôt dans la création de
            l'étape.
          </p>
          <img src={exempleetape} alt="Exemple d'étape" className="centered" />
          <p>
            Si cette étape est répétable, c'est-à-dire réalisée plusieurs fois
            au cours d'une même phase, vous pouvez cocher la case "
            <em>Étape répétable</em>" et indiquer la limite de répétitions
            possible.
          </p>
          <p>
            Lorsque vous avez terminé l'édition de l'étape, n'oubliez pas de
            cliquer sur le bouton <b>Ajouter</b> ou <b>Modifier</b> en haut de
            l'écran.
          </p>

          <p>
            Répétez les opérations de ce chapitre jusqu'à obtenir l'eCRF complet
            de votre étude avant de passez à la mise en production de celle-ci.
          </p>
        </SubSection>
      </Section>
    </AtoZLayout>
  );
}
