import {
  Settings,
  FormatListBulleted,
  GridView,
  AssessmentOutlined,
  CalculateOutlined,
  FactCheckOutlined,
  CasinoOutlined,
  FileDownloadOutlined,
  RuleOutlined,
  ChatOutlined,
  FlagOutlined,
  HistoryOutlined,
  MonitorOutlined,
  AttachFileOutlined,
  AssignmentLateOutlined,
  ScreenSearchDesktop,
  LockOpen,
  ManageAccounts,
  AdminPanelSettings,
  LockOutlined,
  Share,
  ConnectedTv,
} from "@mui/icons-material";

type BaseIconNameType =
  | "FormatListBulleted"
  | "GridView"
  | "AssessmentOutlined"
  | "CalculateOutlined"
  | "FactCheckOutlined"
  | "Shuffle"
  | "FileDownloadOutlined"
  | "RuleOutlined"
  | "ChatOutlined"
  | "FlagOutlined"
  | "HistoryOutlined"
  | "MonitorOutlined"
  | "AttachFileOutlined"
  | "AssignmentLateOutlined"
  | "ScreenSearchDesktop"
  | "LockOpen"
  | "Lock"
  | "ManageAccounts"
  | "AdminPanelSettings"
  | "LockOutlined"
  | "Share"
  | "ConnectedTv";

export type IconNameType = BaseIconNameType | (string & {});

interface IAppIconProps {
  name: IconNameType;
}

export function AppIcon({ name }: IAppIconProps) {
  switch (name) {
    case "FormatListBulleted":
      return <FormatListBulleted />;
    case "GridView":
      return <GridView />;
    case "AssessmentOutlined":
      return <AssessmentOutlined />;
    case "CalculateOutlined":
      return <CalculateOutlined />;
    case "FactCheckOutlined":
      return <FactCheckOutlined />;
    case "Shuffle":
      return <CasinoOutlined />;
    case "FileDownloadOutlined":
      return <FileDownloadOutlined />;
    case "RuleOutlined":
      return <RuleOutlined />;
    case "ChatOutlined":
      return <ChatOutlined />;
    case "FlagOutlined":
      return <FlagOutlined />;
    case "HistoryOutlined":
      return <HistoryOutlined />;
    case "MonitorOutlined":
      return <MonitorOutlined />;
    case "AttachFileOutlined":
      return <AttachFileOutlined />;
    case "AssignmentLateOutlined":
      return <AssignmentLateOutlined />;
    case "ScreenSearchDesktop":
      return <ScreenSearchDesktop />;
    case "LockOpen":
      return <LockOpen />;
    case "Lock":
      return <LockOutlined />;
    case "ManageAccounts":
      return <ManageAccounts />;
    case "AdminPanelSettings":
      return <AdminPanelSettings />;
    case "LockOutlined":
      return <LockOutlined />;
    case "Share":
      return <Share />;
    case "ConnectedTv":
      return <ConnectedTv />;
    default:
      return <Settings />;
  }
}
