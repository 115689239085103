import setup from "@content/tutorial/images/Setup-amico.svg";
import interfacepatients from "@content/tutorial/images/fr/interface-patients.png";
import interfacepatientsavance from "@content/tutorial/images/fr/interface-patients-avance.png";
import interfacedossier from "@content/tutorial/images/fr/interface-dossier.png";
import interfaceformulaire from "@content/tutorial/images/fr/interface-formulaire.png";

import { Note, Section, SubSection, ResponsiveTable } from "@components";

import {
  HelpOutlineOutlined,
  DoneOutlined,
  WarningOutlined,
  Report,
  SignalCellular4Bar,
  SignalCellular2Bar,
  Chat,
  GppGood,
  Lock,
} from "@mui/icons-material";

import { UsingDotterLayout } from "@content/tutorial/using-dotter/Layout";

export default function TutorialUsingDotterStep3() {
  return (
    <UsingDotterLayout
      idx={3}
      lang="fr"
      heroUrl={setup}
      heroAlt="Avancement de l'étude"
    >
      <Section title="Suivi des patients">
        <SubSection title="La vue globale">
          <p>
            La vue <b>Suivi des patients</b> (menu → onglet <b>Live</b>) vous
            permet d'avoir une ensemble de tous les patients de l'étude, qu'ils
            soient inclus ou non. Sur cette page, vous pouvez :
            <li>
              <b>Accéder au dossier d'un patient</b> : en cliquant sur la ligne
              qui lui correspond
            </li>
            <li>
              <b>Rechercher un patient</b> parmi tous les patients de l'étude :
              en cliquant sur la loupe en haut de l'écran puis en tapant son
              identifiant
            </li>
            <li>
              <b>Connaître l'avancement général</b> des inclusions : grâce à la
              barre de progression en haut de l'écran (ne s'affiche que si un
              objectif est défini)
            </li>
            <li>
              <b>Connaître l'avancement détaillé</b> de l'eCRF pour chaque
              patient : en cliquant sur le bouton <em>Vue détaillée</em> en haut
              de l'écran, la vue affiche alors l'avancement de chaque phase
              d'étude pour chaque patient
            </li>
          </p>
          <img
            src={interfacepatients}
            alt="Interface Suivi des patients"
            className="centered"
          />
          <p>
            Pour commencer à compléter l'eCRF d'un patient, cliquez sur la ligne
            qui lui correspond. Si vous venez de créer le patient et que vous
            avez cliqué sur <b>Aller à l'inclusion</b> alors vous accéder
            directement à l'eCRF du patient.
          </p>
        </SubSection>
        <SubSection title="La vue détaillée">
          <img
            src={interfacepatientsavance}
            alt="Interface Suivi des patients avancé"
            className="centered"
          />
          <p>
            La vue détaillée vous permet d'avoir une vision d'ensemble du
            remplissage des formulaires pour tous les patients et par phase.
            L'avancement de chaque phase est représenté par une icône dont voici
            les significations :
          </p>
          <ResponsiveTable>
            <tr>
              <td style={{ textAlign: "center" }}>Icône</td>
              <td>Signification de l'icône</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular4Bar style={{ color: "#B1BABD" }} />
              </td>
              <td>Phase non entamée</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular2Bar style={{ color: "#3498DB" }} />
              </td>
              <td>Phase entamée</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular4Bar style={{ color: "#3498DB" }} />
              </td>
              <td>Phase terminée</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <Lock style={{ color: "#1ABC9C" }} />
              </td>
              <td>Formulaires verrouillés</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <GppGood style={{ color: "#27AE60" }} />
              </td>
              <td>Formulaires signés</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular2Bar style={{ color: "#ffc100" }} /> +{" "}
                <WarningOutlined style={{ color: "#ffc100" }} />
              </td>
              <td>Un formulaire contient un avertissement</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular2Bar style={{ color: "#E74C3C" }} /> +{" "}
                <Report style={{ color: "#E74C3C" }} />
              </td>
              <td>Un formulaire contient une erreur critique</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular2Bar style={{ color: "#3498DB" }} /> +{" "}
                <Chat style={{ color: "#B1BABD" }} />
              </td>
              <td>Des queries ont été posées</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular4Bar style={{ color: "#DFE3E4" }} />
                <SignalCellular2Bar style={{ color: "#ADD5F0" }} />
                <Lock style={{ color: "#A2E4D7" }} />
                <br />
                <span style={{ fontSize: "14px" }}>+ identifiant barré</span>
              </td>
              <td>Le patient a été exclu de l'étude</td>
            </tr>
          </ResponsiveTable>
          <Note type="tip">
            Vous pouvez accéder directement à une phase de l'étude pour un
            patient donné en cliquant sur l'icône qui lui correspond.
          </Note>
        </SubSection>
        <SubSection title="Vue du dossier patient">
          <img
            src={interfacedossier}
            alt="Interface Dossier du patient"
            className="centered"
          />
          <p>
            Sur Dotter.science, les eCRF sont découpés en <b>phases</b> qui sont
            chronologiquement espacées. Lorsque vous êtes sur le dossier
            patient, elles sont représentées par des carrés de couleur (rouge ou
            bleue) et un libellé indique l'occurence de la phase (
            <em>
              exemple : "Visite n°1", "Suivi à 7 jours", "Sortie de l'étude"
            </em>
            ). Vous pouvez naviguer entre les phases simplement en cliquant
            dessus.
          </p>
          <p>
            Selon l'étude, il peut exister des phases particulières :
            <li>
              la <b>Baseline</b> est une phase qui initie l'entrée du patient
              dans l'étude
            </li>
            <li>
              les <b>phases répétables</b> : elles présentent un bouton{" "}
              <b>(+)</b> qui leur permet d'être dupliquées (
              <em>
                par exemple si une phase peut être effectuée plusieurs fois dans
                la même temporalité
              </em>
              )
            </li>
            <li>
              les <b>formulaires spécifiques</b> qui ne sont pas des phases à
              proprement parler mais qui, pour des cas particuliers, contiennent
              des formulaires qu'il faudra remplir en dehors du flux normal de
              l'étude (
              <em>par exemple pour des sorties pré-maturées de l'étude</em>)
            </li>
          </p>
          <p>
            Chaque phase contient elle-même une ou plusieurs <b>étape(s)</b>,
            représentées par les boutons de couleur verte en haut de l'écran et
            qui contiennent le formulaire à remplir. Pour naviguer entre les
            étapes, cliquez sur le bouton qui lui correspond.
          </p>
          <Note type="tip">
            Accédez à des informations supplémentaires du dossier patient en
            cliquant sur les différentes cartes en haut de la page.
          </Note>
        </SubSection>
      </Section>
      <Section title="Compléter l'eCRF">
        <p>
          Lorsque vous avez sélectionné une étape, complétez le formulaire qui
          s'affiche avec vos données d'étude. Certaines questions peuvent être
          désactivées par défaut et ne se débloqueront qu'en fonction de la
          réponse précédente.
        </p>
        <img
          src={interfaceformulaire}
          alt="Interface d'un formulaire"
          className="centered"
        />
        <p>
          À droite de chaque champ, un retour visuel change en fonction des
          données que vous avez fourni :
        </p>
        <ResponsiveTable>
          <tr>
            <td></td>
            <td>Signification du symbole</td>
          </tr>
          <tr>
            <td>
              <DoneOutlined color="success" />
            </td>
            <td>
              indique que le champ est complété et ne renvoie pas d'erreur
            </td>
          </tr>
          <tr>
            <td>
              <HelpOutlineOutlined color="disabled" />
            </td>
            <td>
              indique que le champ n'est pas complété et requiert une donnée
            </td>
          </tr>
          <tr>
            <td>
              <WarningOutlined style={{ color: "#ffc100" }} />
            </td>
            <td>
              indique que le champ pourrait contenir une valeur anormale (si
              valeurs min et max ont été configurées pour ce formulaire)
            </td>
          </tr>
          <tr>
            <td>
              <Report style={{ color: "#E74C3C" }} />
            </td>
            <td>
              indique que le champ contient une valeur abberante (si valeurs min
              et max ont été configurées pour ce formulaire)
            </td>
          </tr>
        </ResponsiveTable>
        <p>
          Remplissez le formulaire avec vos données d'étude puis cliquez sur le
          bouton <b>Enregistrer</b> en bas de l'écran pour valider le
          formulaire.
        </p>
        <Note type="note">
          En cas de problème dans le formulaire (manque de données, erreurs
          mineurs ou résultats abbérants), une fenêtre s'affiche pour vous
          lister les alertes, vous pouvez alors <b>revenir au formulaire</b> ou
          choisir d'<b>enregistrer quand même</b>.
        </Note>
      </Section>
    </UsingDotterLayout>
  );
}
