import events from "@content/tutorial/images/Process-amico.svg";
import interfaceevents from "@content/tutorial/images/fr/interface-events.png";
import filtres from "@content/tutorial/images/fr/filtres-events.png";

import { Note } from "@components";

import { UsingDotterLayout } from "@content/tutorial/using-dotter/Layout";

export default function TutorialUsingDotterStep5() {
  return (
    <UsingDotterLayout
      idx={5}
      lang="fr"
      heroUrl={events}
      heroAlt="Gestion des événements"
    >
      <p>
        L'enregistrement de certains formulaires conduisent automatiquement à
        une modification du statut du patient. C'est le cas pour les formulaires
        d'inclusion ou d'exclusion par exemple. Tous les changements de statut
        de patients sont appelés "<b>événements</b>" et sont répertoriés dans
        une liste accessible à tous.
      </p>
      <p>
        Pour consulter la liste des événements, rendez-vous dans le menu{" "}
        <b>Live</b> puis dans l'onglet <b>Liste des événements</b>
      </p>
      <img
        src={interfaceevents}
        alt="Liste des événements"
        className="centered"
      />
      <p>
        Sur cette vue, vous pouvez accéder à l'ensemble des événement de
        l'étude. En cliquant sur un événement, vous accédez directement au
        formulaire qui a induit l'événement en question.
      </p>
      <Note type="tip">
        Pour faciliter la recherche d'événements, vous pouvez filtrer la liste
        en cliquant sur le bouton "<b>Ajouter un filtre</b>" en haut à droite de
        l'écran. Vous pourrez ainsi les trier en fonction de différents critères
        (état, centre émetteur, date).
        <img src={filtres} alt="Filtres des queries" className="centered" />
      </Note>
    </UsingDotterLayout>
  );
}
