import events from "@content/tutorial/images/Process-amico.svg";
import interfaceevents from "@content/tutorial/images/en/events-list.png";
import filtres from "@content/tutorial/images/en/events-filters.png";

import { Note } from "@components";

import { UsingDotterLayout } from "@content/tutorial/using-dotter/Layout";

export default function TutorialUsingDotterStep5() {
  return (
    <UsingDotterLayout
      idx={5}
      lang="en"
      heroUrl={events}
      heroAlt="Event Management"
    >
      <p>
        The recording of certain forms automatically leads to a change in the
        patient's status. This is the case for inclusion or exclusion forms, for
        example. All changes in patient status are called "<b>events</b>" and
        are listed in an accessible inventory.
      </p>
      <p>
        To consult the list of events, go to the <b>Live</b> menu and then to
        the <b>Events List</b> tab.
      </p>
      <img src={interfaceevents} alt="Events List" className="centered" />
      <p>
        In this view, you can access all the events of the study. By clicking on
        an event, you can directly access the form that triggered the specific
        event.
      </p>
      <Note type="tip">
        To facilitate the search for events, you can filter the list by clicking
        the "<b>Add a filter</b>" button at the top right of the screen. You
        will be able to sort them based on various criteria (status, issuing
        center, date).
        <img src={filtres} alt="Query Filters" className="centered" />
      </Note>
    </UsingDotterLayout>
  );
}
