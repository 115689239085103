import eproaccesspage from "@content/guides/images/fr/epro-access-page.png";
import eproform from "@content/guides/images/fr/epro-form.png";
import epronotyetavailable from "@content/guides/images/fr/not-yet-available.png";
import epronomoreavailable from "@content/guides/images/fr/no-more-available.png";

import { PageLayout, Section, SubSection, AppIcon } from "@components";

import "@styles/Page.scss";

export default function GuideEproPatient() {
  return (
    <PageLayout
      title="Guide d'utilisation patient pour la saisie ePRO"
      titleIcon={<AppIcon name="ConnectedTv" />}
      titleClassName="guide"
      lang="fr"
    >
      <Section id="epro-patient-intro" title="Introduction">
        <p>
          Vous avez été invité à participer à une étude clinique, et dans ce
          cadre, il vous est demandé de remplir des formulaires à distance. Ce
          guide est là pour vous aider à comprendre le fonctionnement de ce
          module. Les indications présentées ici sont d'ordre général, mais si
          vous avez des questions spécifiques concernant l'étude à laquelle vous
          participez (par exemple, si vous ne comprenez pas bien ce qui est
          attendu pour une question particulière), veuillez contacter le centre
          investigateur qui assure votre suivi dans l'étude.
        </p>
      </Section>
      <Section id="epro-patient-general" title="Fonctionnement général">
        <p>
          Dans certaines études cliniques, il est demandé aux patients de saisir
          directement des formulaires depuis leur ordinateur, tablette ou
          téléphone portable. Cela vous permet de contribuer directement depuis
          chez vous, sans avoir besoin de vous déplacer en centre de manière
          systématique.
        </p>
        <SubSection title="Réception des courriels d'invitation">
          <p>
            En fonction des études, il peut vous être demandé de remplir un ou
            plusieurs formulaires, parfois de manière régulière (par exemple,
            une semaine après un examen, puis trois mois plus tard). Pour chaque
            formulaire que vous devez remplir, vous recevrez un courriel (ou
            "e-mail") distinct. Chaque courriel contient des informations
            techniques telles que le nom de l'étude et le nom du formulaire à
            remplir, ainsi qu'un bouton avec un lien pour effectuer la saisie.
            Le lien est unique pour chaque formulaire et ne peut pas être
            utilisé pour un autre formulaire.
          </p>
          <p>
            Les formulaires ont une durée de validité définie par l'organisateur
            de l'étude (promoteur). Vous recevrez un courriel d'invitation dès
            le premier jour de la période de validité. Un courriel de rappel
            sera envoyé tous les sept jours jusqu'à ce que la saisie soit
            complète ou que le formulaire ne soit plus valide. Les courriels
            sont généralement envoyés le matin vers 10h (heure de Paris).
          </p>
          <p>
            <b>Attention :</b> si vous devez remplir plusieurs formulaires
            simultanément, vous recevrez plusieurs courriels d'invitation.
            Pensez bien à remplir tous les formulaires.
          </p>
        </SubSection>
        <SubSection title="Connexion à la plateforme">
          <p>
            Le courriel d'invitation contient un lien qui vous redirige vers une
            page de connexion sur le site internet Dotter.science (il s'agit de
            la plateforme choisie par l'organisateur de l'étude, ou promoteur,
            pour gérer les données de l'étude clinique).
          </p>
          <img
            src={eproaccesspage}
            alt="Page d'accès ePRO (patient)"
            className="centered"
          />
          <p>
            Le lien doit vous envoyer vers une page de connexion où il vous sera
            demandé un code nécessaire pour valider qu'il s'agit bien de vous.
            Si vous rencontrez un message d'erreur ou un souci technique,
            consultez la section "Dépannage" ci-après.
          </p>
          <p>
            Le code d'accès vous a été fourni par votre centre investigateur.
            Dans certains cas, le promoteur peut choisir d'utiliser un code basé
            sur des informations personnelles (par exemple votre année de
            naissance), et une aide vous précisera ce que vous devez saisir. En
            cas de difficultés, consultez la section "Dépannage" ci-après.
          </p>
        </SubSection>
        <SubSection title="Saisie des informations">
          <p>
            Une fois votre code d'accès validé, vous arriverez sur le formulaire
            à remplir.
          </p>
          <img src={eproform} alt="Formulaire ePRO" className="centered" />
          <p>
            Saisissez les différents champs demandés au mieux. Si vous avez des
            questions sur la manière de remplir les données, consultez la
            documentation fournie par le centre investigateur ou contactez-le
            pour obtenir de l'aide.
          </p>
          <p>
            Vous pouvez enregistrer le formulaire à tout moment. Il n'est donc
            pas nécessaire de remplir complètement le formulaire pour
            l'enregistrer.{" "}
            <b>
              Nous vous recommandons d'enregistrer régulièrement le formulaire
            </b>{" "}
            pour limiter le risque de perte de votre progression. En effet, si
            la page est fermée (par exemple à la suite d'une mauvaise
            manipulation ou en cas de coupure électrique), les données saisies
            et non enregistrées seront perdues et vous devrez recommencer.
          </p>
          <p>
            Une fois que le formulaire est enregistré, vous pouvez fermer la
            page et y revenir plus tard. Les données sauvegardées seront
            récupérées.
          </p>
        </SubSection>
      </Section>
      <Section id="epro-patient-troubleshooting" title="Dépannage">
        <SubSection title="Je ne reçois pas les courriels d'invitation">
          <p>
            Si vous avez l'impression de ne pas recevoir les courriels
            d'invitation, suivez les étapes suivantes :
            <ol>
              <li>
                Vérifiez dans votre boîte mail s'ils n'ont pas été détectés par
                erreur comme indésirables et déplacés dans un dossier "courrier
                indésirable" (ou "spam"). Attention, en fonction de la
                configuration de votre fournisseur, les courriels considérés
                comme indésirables peuvent être supprimés automatiquement au
                bout de quelques jours (par exemple, 30 jours avec Gmail).
              </li>
              <li>
                Si vous ne trouvez toujours pas de courriel d'invitation,
                contactez le centre investigateur qui vous suit pour cette étude
                (vous avez dû recevoir un document d'information au moment de la
                signature de votre consentement pour la participation à
                l'étude).
              </li>
            </ol>
          </p>
        </SubSection>
        <SubSection title="J'ai un message d'erreur en arrivant sur la page de connexion">
          <p>
            Si en arrivant sur la page de connexion vous trouvez un{" "}
            <b>message vous indiquant que le formulaire n'est plus valide</b>,
            cela signifie que vous avez dépassé la date de validité pour la
            saisie. Contactez votre centre investigateur pour déterminer si la
            période de saisie peut être étendue.
          </p>
          <img
            src={epronomoreavailable}
            alt="Le formulaire n'est plus disponible"
            className="centered"
          />
          <p>
            Si en arrivant sur la page de connexion vous trouvez un{" "}
            <b>
              message vous indiquant que le formulaire n'est pas encore valide
            </b>
            , il est possible que la date de début de validité ait été modifiée
            après l'envoi du courriel. Contactez votre centre investigateur pour
            obtenir plus d'informations.
          </p>
          <img
            src={epronotyetavailable}
            alt="Le formulaire n'est pas encore disponible"
            className="centered"
          />
          <p>
            Si vous constatez{" "}
            <b>
              un autre message vous empêchant d'accéder à la fenêtre de
              connexion
            </b>
            , vous devez contacter votre centre investigateur et décrire au
            mieux votre problème. Pour faciliter la recherche du problème, et si
            vous avez les compétences techniques suffisantes, faites une capture
            d'écran ("screenshot") qui consiste à enregistrer l'image de votre
            écran dans un fichier.
          </p>
        </SubSection>
        <SubSection title="J'ai un problème technique qui m'empêche de me connecter ou de saisir mes données">
          <p>
            Si en arrivant sur la page de connexion{" "}
            <b>rien ne s'affiche (aucun affichage, aucun message d'erreur)</b>,
            vérifiez que votre connexion à Internet est bien fonctionnelle
            (vérifiez si vous pouvez naviguer sur d'autres sites sans
            difficulté). Si vous n'avez pas de problème de connexion, contactez
            votre centre investigateur et décrivez au mieux votre problème.
          </p>
          <p>
            Si la page de connexion s'affiche correctement mais que vous avez un
            message d'erreur indiquant un <b>problème d'authentification</b> (le
            message indique que le code est invalide), vérifiez que vous n'avez
            pas fait d'erreur de saisie, puis vérifiez à nouveau votre connexion
            Internet. Si tout semble en ordre, contactez votre centre
            investigateur et décrivez au mieux votre problème.
          </p>
          <p>
            Si vous avez réussi à vous connecter, mais que{" "}
            <b>vous n'arrivez pas à enregistrer le formulaire</b> (message
            d'erreur lors de l'enregistrement), n'insistez pas et contactez
            votre centre investigateur dans les plus brefs délais. Dans la
            mesure du possible, conservez votre page web ouverte, car s'il ne
            s'agit que d'un problème temporaire de quelques minutes, cela vous
            évitera de perdre votre progression et de l'enregistrer un peu plus
            tard.
          </p>
        </SubSection>
        <SubSection title="Puis-je vous contacter directement en cas de problème ?">
          <p>
            Non, il est préférable de contacter votre centre investigateur en
            cas de difficultés. Les personnes en charge de votre suivi dans
            l'étude seront plus à même de vous guider en fonction de vos besoins
            et des spécificités de l'étude.
          </p>
          <p>
            Afin de faciliter le traitement de vos demandes, pensez à fournir un
            maximum d'informations sur votre difficulté. Si le problème est de
            nature purement technique, les centres investigateurs nous
            recontacteront directement et reviendront vers vous si besoin (il
            pourrait s'agir d'un problème déjà en cours de résolution). Dans de
            très rares cas, si nous ne parvenons pas à comprendre le problème,
            et en accord avec le promoteur et le centre investigateur, nous
            pourrions être amenés à vous recontacter directement.
          </p>
        </SubSection>
      </Section>
    </PageLayout>
  );
}
