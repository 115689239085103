import schema from "@content/advanced/images/en/locking-flow.png";
import boutons from "@content/advanced/images/en/locking-buttons.png";
import validation from "@content/advanced/images/en/locking-validation.png";

import { PageLayout, Section, Note, ResponsiveTable } from "@components";

import "@styles/Page.scss";

export default function FeatLockSign() {
  return (
    <PageLayout title="Locking and Signing" lang="en">
      <Section id="steps-to-lock-a-form" title="Steps to Lock a Form">
        <p>
          During a study, each form must go through several stages from
          completion to final validation by the study manager.
        </p>
        <img
          src={schema}
          alt="Locking a Form"
          className="fullwidth"
          style={{ boxShadow: "none" }}
        />
        <p>
          As shown in the diagram above, forms have 4 statuses, each with
          varying restrictions, and only certain roles can act at each stage of
          the form.
        </p>
        <ResponsiveTable>
          <tr>
            <td>Form Status</td>
            <td>Purpose and Relevant Roles</td>
            <td>Possible Changes</td>
          </tr>
          <tr>
            <td>
              <b>Open</b>
            </td>
            <td>
              Data collection and completion by <b>CST</b> and <b>CST+</b>.
            </td>
            <td>
              <b>Lock the form:</b> Everyone
            </td>
          </tr>
          <tr>
            <td>
              <b>Locked</b>
            </td>
            <td>
              Data collection is blocked, and the form is awaiting monitoring.
            </td>
            <td>
              <b>Monitor the form:</b> CRA/Coordinating CRA or Administrator
              (global or local)
              <br />
              <b>Unlock the form:</b> CRA/Coordinating CRA or Administrator
              (global or local)
            </td>
          </tr>
          <tr>
            <td>
              <b>Monitored</b>
            </td>
            <td>
              Data is analyzed by <b>CRAs</b> and <b>Coordinating CRAs</b>. The{" "}
              <a href="/en/advanced/queries">
                <b>queries</b>
              </a>{" "}
              are always accessible.
            </td>
            <td>
              <b>Sign the form:</b> Investigator and Coordinating Investigator,
              Administrator (global or local)
              <br />
              <b>Unmonitor the form:</b> CRA/Coordinating CRA or Administrator
              (global or local)
            </td>
          </tr>
          <tr>
            <td>
              <b>Validated and Signed</b>
            </td>
            <td>
              Final stage of the form, data is approved by the study manager(s).
            </td>
            <td>
              <b>Remove the signature from the form:</b> Administrator (global
              or local)
            </td>
          </tr>
        </ResponsiveTable>
        <Note type="note">
          To understand the concept of roles and the abbreviations used, you can
          refer to the guide on{" "}
          <a href="/en/guides/roles">Roles and Permissions</a>.
        </Note>
      </Section>
      <Section
        id="lock-monitor-sign-a-form"
        title="Lock, Monitor, or Sign a Form"
      >
        <p>
          To change the status of a form, regardless of your role in the study,
          go to the relevant form. In the bottom menu bar, a{" "}
          <b>contextual button</b> will appear based on the change you can make:
        </p>
        <img
          src={boutons}
          alt="Locking Buttons"
          className="centered"
          style={{ boxShadow: "none" }}
        />
        <p>
          Click the appropriate button to perform the desired action. A
          validation window will open, prompting you to re-enter the{" "}
          <b>4-digit random code</b> displayed on the screen. This measure
          ensures additional security in managing forms.
          <br />
          The message displayed varies slightly depending on the change made,
          but the principle remains the same for each stage and each role.
        </p>
        <img src={validation} alt="Locking Validation" className="centered" />
        <p>
          Click the <b>green button</b> to complete your action. A message will
          appear in the bottom left corner of the screen to confirm the
          procedure.
        </p>
      </Section>
    </PageLayout>
  );
}
