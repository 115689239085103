import Logo from "@images/logo-dotter-documentation-white.svg";

import { useTranlations, useLangRouteGenerator, AppLanguages } from "@helpers";

interface ILogoLinkProps {
  lang?: AppLanguages;
}

export function LogoLink({ lang }: ILogoLinkProps) {
  const tkeys = useTranlations();
  const genRoute = useLangRouteGenerator();

  return (
    <a className="navbar-left" href={genRoute("/", lang)}>
      <img src={Logo} />
      <span className="navbar-title">
        <h1>Dotter.science</h1>
        <h3>{tkeys.topMenu.documentation}</h3>
      </span>
    </a>
  );
}
