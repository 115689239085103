import switchecrf from "@content/guides/images/en/switch-ecrf.png";
import eprosettings from "@content/guides/images/en/epro-settings.png";
import eproinvitsempty from "@content/guides/images/en/epro-empty-invit-list.png";
import eproinvit from "@content/guides/images/en/epro-invit-list.png";

import { PageLayout, Section, SubSection, AppIcon } from "@components";

import "@styles/Page.scss";

export default function GuideEproLogin() {
  return (
    <PageLayout
      title="ePRO Setup Guide"
      titleIcon={<AppIcon name="Share" />}
      titleClassName="guide"
      lang="en"
    >
      <p>
        The ePRO module from Dotter.science allows subjects to fill out forms
        independently on their own devices, streamlining data collection without
        manual intervention. This feature enhances data quality by minimizing
        transcription errors. Dotter.science's team manages the initial design
        and setup for the sponsor (i.e., the clinical study organizer), which
        includes defining the questionnaires and configuring the general study
        settings.
      </p>
      <p>
        However, you are responsible for the individual setup of each subject.
        This setup involves entering the subject’s email address to send
        invitations and creating a unique access code to ensure data security.
        It is essential that each subject is configured correctly to receive
        invitations on time and complete the required forms according to the
        study schedule.
      </p>
      <p>
        Each subject will receive an email invitation for every form, based on
        predefined dates, with automatic reminders if a form is not completed.
        You can adjust these validity dates and even complete the forms yourself
        if subjects encounter difficulties or if you wish to complete them with
        them over the phone.
      </p>
      <p>
        This guide is designed to help you through all the necessary steps for
        configuring individual subjects: accessing settings, entering contact
        information, generating invitations, and tracking form completion. This
        ensures that each subject is properly registered and that data is
        collected accurately and on time, meeting the requirements of the
        clinical protocol.
      </p>
      <Section id="epro-general-workflow" title="General Workflow">
        <p>
          After completing the ePRO setup for a study and a subject, each
          subject will receive a <b>separate email invitation for each form</b>{" "}
          that needs to be completed, according to the timelines defined in the
          study calendar. Each email contains specific study information, the
          name of the form to be completed, and a secure link for the subject to
          enter their data.
        </p>
        <p>
          Email invitations are typically sent in the morning around 10 AM
          (Paris time). Currently, neither the sending time nor the time zone is
          adjustable. If the subject does not complete an ePRO form, a reminder
          is automatically sent every 7 days until the form is completed or
          until it expires. These settings cannot be modified.
        </p>
        <p>
          If multiple ePRO forms need to be completed on the same day, each form
          will generate a distinct email, which will be sent simultaneously.
          This means the subject might receive several invitations on the same
          day, especially if multiple ePRO forms share the same validity dates.
        </p>
        <p>
          You can <b>modify the validity dates of the forms</b> if needed. For
          example, this may be helpful if a subject experiences temporary
          technical difficulties that prevent them from completing the form
          within the original timeline. This feature helps maintain the
          flexibility needed for effective data collection.
        </p>
        <p>
          The ePRO forms are integrated into the overall study workflow
          alongside other non-ePRO forms and are marked with a banner indicating
          that they must be completed by the subjects. If a subject encounters
          difficulty entering data through the web interface, alternatives such
          as paper forms or phone calls can be used, and you can manually enter
          the data into the ePRO system. Any changes made to the forms, whether
          by the subjects or by study staff, are always tracked to ensure
          complete transparency of the data.
        </p>
      </Section>
      <Section id="epro-subject-settings" title="Subject Setup">
        <SubSection title="Step 1: Select the Subject">
          <p>
            To begin, select the desired subject by navigating to the "Patient
            file" section. Click the button located at the top right of the form
            to move to the relevant section.
          </p>
          <img src={switchecrf} alt="Switch vers eCRF" className="centered" />
        </SubSection>
        <SubSection title="Step 2: Access the ePRO Setup">
          <p>
            Once in the subject's record, click on the "ePRO Configuration"
            button located on the left-hand side of the screen.
          </p>
          <img src={eprosettings} alt="Paramétrage ePRO" className="centered" />
        </SubSection>
        <SubSection title="Step 3: Verify Editing Permissions">
          <p>
            If the "email" and "subject access code" fields are not editable,
            this means you do not have the necessary permissions to view and
            edit this subject’s sensitive data. These permissions are granted by
            the study administrators. If you cannot access this information,
            please contact a study administrator to obtain the required
            authorization.
          </p>
        </SubSection>
        <SubSection title="Step 4: Enter the Email Address">
          <p>
            In the "email" field, enter the subject's email address. Make sure
            this address is correct, as it will not be automatically verified
            before invitations are sent. Any mistake in the email address could
            prevent the subject from receiving the invitations, delaying their
            participation in the study.
          </p>
        </SubSection>
        <SubSection title="Step 5: Create the Subject Access Code">
          <p>
            The "subject access code" field is open-ended. You can enter a
            combination of characters or numerical values. Note that the subject
            will not automatically receive an email with this access code. It is
            your responsibility to provide this code to the subject through a
            secure channel. The sponsor may specify particular rules for
            constructing this code, such as using the subject's year of birth.
            It is important to follow these recommendations whenever possible,
            as they facilitate the subject’s access to the study (for example,
            by providing hints on the login page).
          </p>
        </SubSection>
        <SubSection title="Step 6: Enter the Reference Date">
          <p>
            Finally, enter a reference date, which will be used to automatically
            calculate the validity dates of the ePRO invitations. This date
            might correspond, for example, to the subject’s inclusion date in
            the study. Once the invitations are generated, this reference date
            will no longer impact the validity dates of the forms.
          </p>
        </SubSection>
      </Section>
      <Section id="epro-generate-invitations" title="Generating Invitations">
        <SubSection title="Step 1: Generate Invitations">
          <p>
            To generate invitations based on the investigator’s plan, click on
            the "Generate Invitations" button. This button becomes available
            once all subject information has been correctly set up.
          </p>
          <img
            src={eproinvitsempty}
            alt="Generate Invitations"
            className="centered"
          />
          <p>
            <b>Important Note:</b> To generate invitations, the subject must be
            fully set up, included, and randomized in the study. Ensure that all
            these conditions are met before proceeding. Once these conditions
            are satisfied, the invitations should appear in the relevant
            section.
          </p>
          <img
            src={eproinvit}
            alt="Generated invitations"
            className="centered"
          />
        </SubSection>
        <SubSection title="Step 2: Modify Validity Dates">
          <p>
            After generating the invitations, the validity dates of the forms
            are automatically calculated based on the parameters set for the
            ePRO module and the subject’s reference date. If necessary, these
            dates can be adjusted manually.
          </p>
          <p>
            To modify a validity date, click on the three dots at the end of the
            form line and select "Edit." A popup will open, allowing you to
            adjust the start and end dates of the invitation's validity.
          </p>
          <p>
            These adjustments can help adapt data collection to the specific
            circumstances of the subjects, ensuring maximum flexibility in
            managing timelines.
          </p>
        </SubSection>
      </Section>
      <Section id="epro-followup" title="Monitoring Form Completion">
        <p>
          The completion of ePRO forms is tracked using a color-coded system
          associated with an envelope icon. This color-coding helps you quickly
          determine the status of each form, making it easier to manage data
          collection and decide on necessary actions.
        </p>
        <SubSection title="Color Code Meanings">
          <ul>
            <li>
              <b>Gray:</b> The form is <b>not active</b>. This means the
              validity period has not yet begun, and the form is not available
              for the subject.
            </li>
            <li>
              <b>Cyan:</b> The form is <b>active</b>, but no data has been
              entered yet. The subject has received the invitation but has not
              started filling in the form.
            </li>
            <li>
              <b>Yellow:</b> The form is <b>partially completed</b>. This
              indicates that the subject has started entering data, but some
              fields are still incomplete. A manual follow-up may be needed to
              ensure the subject did not encounter any issues and to encourage
              them to complete the form.
            </li>
            <li>
              <b>Green:</b> The form is <b>fully completed</b>. All fields have
              been filled in successfully, and no further action is required.
            </li>
            <li>
              <b>Red:</b> The form is <b>expired</b> without being fully
              completed. This means that the validity period has ended, and the
              form was not completed in time.
            </li>
          </ul>
          <p>
            This visual tracking system allows you to efficiently manage data
            collection by quickly identifying forms that require attention,
            whether it involves following up with a subject or checking
            incomplete information.
          </p>
        </SubSection>
      </Section>
    </PageLayout>
  );
}
