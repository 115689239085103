import ecrf from "@content/tutorial/images/Audit-amico.svg";
import schemaecrf from "@content/tutorial/images/en/schema-ecrf.png";
import studyedit from "@content/advanced/images/en/access-study-editing.png";
import switchbutton from "@content/advanced/images/en/switch-models-editing.png";
import gotovariables from "@content/tutorial/images/en/switch-type-variables.png";
import typesvariable from "@content/advanced/images/en/list-type-variables.png";
import gotomodels from "@content/tutorial/images/en/switch-type-models.png";
import editmodelname from "@content/tutorial/images/en/edit-new-model-name.png";
import editionsection from "@content/tutorial/images/en/edit-new-section.png";
import ajoutcondtionnav from "@content/tutorial/images/en/add-activation-condition.png";
import conditions from "@content/tutorial/images/en/activation-condition.png";

import { Note, Section, SubSection } from "@components";

import { AtoZLayout } from "@content/tutorial/a-to-z/Layout";

export default function TutorialAtoZStep4() {
  return (
    <AtoZLayout lang="en" idx={4} heroUrl={ecrf} heroAlt="eCRF">
      <Section title="Understanding How an eCRF Works on Dotter.science">
        <p>
          On Dotter.science, the eCRF is a set of <b>forms</b> that integrate
          into each phase of the study under the term "<b>form templates</b>".
          This allows for the creation of standard forms that can be reused
          multiple times at different stages of the study without needing to
          recreate them each time.
        </p>
        <p>
          The forms themselves contain a series of questions or measurements
          referred to as "<b>variables</b>". These variables, like the forms,
          are stored in a database under the term "<b>variable templates</b>"
          and can be reused at various points throughout the study.
        </p>
        <img
          src={schemaecrf}
          alt="eCRF Diagram"
          className="fullwidth"
          style={{ boxShadow: "none" }}
        />
        <p>
          In order, you will first create all your variables, then insert them
          into form templates, and finally integrate these form templates into
          the timeline of the study. This is how we will proceed in the rest of
          this tutorial.
        </p>
      </Section>
      <Section title="Creating Templates">
        <p>
          The creation of templates, whether for variables or forms, will take
          place within the same Dotter.science interface. To do this, go to the{" "}
          <b>Design</b> menu and then the <b>Study Editing</b> tab.
        </p>
        <img src={studyedit} alt="Study Editing Tab" className="centered" />
        <p>
          Switch the interface to <b>Template Editing</b> mode by clicking the
          toggle button at the top right of your screen.
        </p>
        <img
          src={switchbutton}
          alt="Switch to Template Editing Mode"
          className="centered"
        />
        <SubSection title="Variable Templates">
          <p>
            Start by creating the variables that will be used in your forms. Go
            to the list of variables by clicking on <b>Variables</b> at the
            bottom of the screen.
          </p>
          <img
            src={gotovariables}
            alt="List of Variables"
            className="centered"
          />
          <p>
            All created variables will appear on the left area. To access and
            modify them, simply click on them. Let's start by creating the first
            variable. To do this, click the "<b>+</b>" button at the top of the
            list. A window will open to offer you several types of variables.
          </p>
          <img
            src={typesvariable}
            alt="Types of Variables"
            className="centered"
          />
          <p>
            Click on the type of variable that suits your needs, and a new
            window will appear for configuration. For multiple choice and
            calculation variables, you must configure the{" "}
            <b>Advanced Settings</b> tab; for others, it is not mandatory.
          </p>
          <Note type="tip">
            To better understand how to use and configure variables in detail
            (defining default values, limits, alerts, etc.), you can refer to
            the specific documentation for{" "}
            <a href="/en/advanced/variables" target="_blank">
              Variables (Simple)
            </a>
            .
          </Note>
          <p>
            Repeat the process for each variable you will use in your eCRF
            before moving on to creating form templates.
          </p>
        </SubSection>
      </Section>
      <Section title="Form Templates">
        <p>
          You have just created a set of variables that now need to be inserted
          into your forms. Switch to template editing by clicking the{" "}
          <b>Templates</b> button at the bottom of the screen.
        </p>
        <img src={gotomodels} alt="List of Templates" className="centered" />
        <p>
          Create a new form template by clicking the "<b>+</b>" button at the
          top of the list. The editing section on the right of the screen will
          then display the empty form template. Initially, hover over the name
          of the template and click the pencil icon that appears to name your
          form template.
        </p>
        <img
          src={editmodelname}
          alt="Edit Template Name"
          className="centered"
        />
        <p>
          You can then specify an associated event type for this form, meaning a
          consequence that will occur when this form is recorded in the
          patient's record (to learn more about event types, refer to the
          documentation on{" "}
          <a href="/en/advanced/events">
            <b>Events</b>).
          </a>
        </p>
        <p>
          Add a first section by clicking the "<b>+ Add a Section</b>" button.
          You can then give it a name or leave the title blank to simply space
          the fields in the form.
        </p>
        <p>
          To add variables to this section, hover over the section to reveal two
          buttons:
          <li>
            the first allows you to retrieve a variable you previously created
          </li>
          <li>the second allows you to create a new variable</li>
        </p>
        <img
          src={editionsection}
          alt="Insert a Variable"
          className="centered"
        />
        <p>
          It is also possible to activate certain questions based on the
          responses to other questions, which is known as{" "}
          <a href="/en/advanced/conditional-navigation">
            <b>conditional navigation</b>
          </a>
          . To apply it to a variable, hover over it and click the{" "}
          <b>Edit Activation Condition</b> icon.
        </p>
        <img
          src={ajoutcondtionnav}
          alt="Edit Activation Conditions"
          className="centered"
        />
        <p>
          In the window that opens, click the arrows next to{" "}
          <em>No variable selected</em>, then choose the variable that will
          activate the current variable. Next, click the <b>+ Condition</b>{" "}
          button and define the result condition that will activate the current
          variable.
        </p>
        <img
          src={conditions}
          alt="Activation Conditions"
          className="centered"
        />
        <p>
          Click <b>Validate</b> to confirm your choice. A small green icon will
          appear next to your variable indicating that it will display
          conditionally.
        </p>
        <Note type="tip">
          To better understand how conditional variables work and improve their
          rendering in the eCRF, you can read the documentation on{" "}
          <a href="/en/advanced/conditional-navigation">
            <b>Conditional Navigation</b>.
          </a>
        </Note>
        <p>
          Repeat the process to add sections containing the variables you need.
          Once your template is complete, click the "<b>SAVE</b>" button at the
          top of the screen.
        </p>
        <Note type="caution">
          Any unsaved changes are <b>permanently lost</b>. Remember to save your
          template at the end of each modification.
        </Note>
      </Section>
    </AtoZLayout>
  );
}
