import launch from "@content/tutorial/images/Launching-amico.svg";
import ongletadmin from "@content/tutorial/images/en/design-admin.png";
import etatdeletude from "@content/tutorial/images/en/study-status.png";
import ongletexport from "@content/tutorial/images/en/exports-menu.png";
import nouvelexport from "@content/tutorial/images/en/new-export.png";

import { Note, Section, ResponsiveTable } from "@components";

import { AtoZLayout } from "@content/tutorial/a-to-z/Layout";

export default function TutorialAtoZStep6() {
  return (
    <AtoZLayout
      lang="en"
      idx={6}
      heroUrl={launch}
      heroAlt="Starting the Study and Exports"
    >
      <Section title="Move the Study to Production">
        <Note type="caution">
          Moving the study to production is currently <b>disabled</b> for study
          administrators. To change the study status,{" "}
          <a href="mailto:contact@dotter.science">
            contact a Dotter.science administrator
          </a>
          .
        </Note>
        <p>
          When your eCRF is ready, you can move your study to production to
          start the inclusions and enter the first data. Go to the <b>Design</b>{" "}
          menu and click on the <b>Administrative</b> tab.
          <img
            src={ongletadmin}
            alt="Administrative Tab"
            className="centered"
          />
        </p>

        <p>
          Go to the <b>Study Status</b> block. Currently, your study is in the{" "}
          <b>Design</b> phase. To move it to production, click on the{" "}
          <b>Active</b> status. A notification will confirm that the study
          status has been successfully updated.
        </p>
        <img src={etatdeletude} alt="Study Status" className="centered" />
        <ResponsiveTable>
          <tr>
            <td>Status</td>
            <td>Meaning</td>
          </tr>
          <tr>
            <td>Design</td>
            <td>
              Allows for editing the eCRF and creating "dummy" patients to test
              the functionality of the collection forms.
            </td>
          </tr>
          <tr>
            <td>Active</td>
            <td>
              The eCRF is validated, data inclusion and entry period for the
              study.
            </td>
          </tr>
          <tr>
            <td>Analysis</td>
            <td>
              End of inclusions, data is accessible for statistical analysis.
            </td>
          </tr>
          <tr>
            <td>Archived</td>
            <td>
              Data is compiled and accessible only on request (a{" "}
              <em>Completed</em> intermediate function is available).
            </td>
          </tr>
        </ResponsiveTable>
        <Note type="tip">
          If during the study you wish to pause it, click the small{" "}
          <em>Pause</em> icon under the <em>Active</em> status. Data entry will
          then be blocked until the study is set back to active.
        </Note>
      </Section>
      <Section title="Exporting Study Data">
        <p>
          During the study, you can export data in <b>.csv</b> or <b>.xlsx</b>{" "}
          format for visualization, processing, analysis, etc. To do this, go to
          the <b>Analysis</b> menu and then the <b>Exports</b> tab.
        </p>
        <img src={ongletexport} alt="Exports Tab" className="centered" />
        <p>
          Click on <b>New Export</b> to request an export. The server will
          process your request, which may take a few minutes.
        </p>
        <img src={nouvelexport} alt="New Export" className="centered" />
        <Note type="note">
          <p>
            If the export request does not appear immediately, click the "
            <b>Reload</b>" button at the bottom of the list.
          </p>
        </Note>
      </Section>
    </AtoZLayout>
  );
}
