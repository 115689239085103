import openquery from "@content/advanced/images/en/open-query.png";
import redigerquery from "@content/advanced/images/en/query-modal.png";
import suiviquery from "@content/advanced/images/en/query-followup.png";
import accesqueries from "@content/advanced/images/en/queries-list-menu.png";
import filtres from "@content/tutorial/images/en/queries-filter.png";

import { PageLayout, Section, Note } from "@components";

import "@styles/Page.scss";

export default function FeatQueries() {
  return (
    <PageLayout title="Queries" roles={["admin", "arc"]} lang="en">
      <Section id="opening-a-query" title="Opening a Query">
        <p>
          <b>Queries</b> are notes that can be manually added to any entry of
          the eCRF and listed in a table for you to consult later for an
          overview.
        </p>
        <p>
          To open a query, hover over the desired eCRF field, and a dialog
          bubble will appear to its right. Click on the bubble to open the query
          editing.
        </p>
        <img src={openquery} alt="Adding a Query" className="centered" />
        <p>
          Write your query and then click "<b>Send</b>"
        </p>
        <img src={redigerquery} alt="Writing a Query" className="centered" />
        <Note type="tip">
          You can enhance your message by applying formatting. To do this,
          simply surround the desired text with the following symbols:{" "}
          <b>**for bold**</b>, <em>_for italic_</em>, and{" "}
          <del>~~for strikethrough~~</del>.
        </Note>
        <p>
          When a query is opened, the window displays the complete tracking of
          the initial query and its responses. You can respond by adding an
          additional message and/or changing the status of the query.
        </p>
        <img src={suiviquery} alt="Tracking a Query" className="centered" />
      </Section>
      <Section id="status-of-queries" title="Status of Queries">
        <p>
          When you write a query for the first time, its status is by default
          set on <b>Open</b>, but it can be modified later. To do so, open the
          query from the patient folder and then click the dropdown menu "
          <b>Change status to ...</b>" at the bottom of the window. The
          different possible statuses are:
          <li>Open</li>
          <li>Confirmed</li>
          <li>Resolved</li>
          <li>Unverifiable</li>
          <li>Closed</li>
          To validate the change, click <b>Send</b>. The status change is added
          to the query history.
        </p>
      </Section>
      <Section id="consulting-queries" title="Consulting Queries">
        <p>
          To get an overview of all the queries in the study, go to the{" "}
          <b>Live</b> menu and then the <b>Query List</b> tab.
        </p>
        <img src={accesqueries} alt="Queries Tab" className="centered" />
        <p>Click on any query to view its content.</p>
        <Note type="tip">
          To facilitate the search of specific queries, you can filter queries
          by clicking the "<b>Add a Filter</b>" button at the top right of the
          screen. This way, you can sort them based on various criteria (status,
          issuing center, date).
          <img src={filtres} alt="Query Filters" className="centered" />
        </Note>
      </Section>
    </PageLayout>
  );
}
