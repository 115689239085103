import eventtypes from "@content/advanced/images/fr/event-types.png";
import eventbell from "@content/advanced/images/fr/event-bell.png";
import interfaceevents from "@content/tutorial/images/fr/interface-events.png";

import { PageLayout, Section } from "@components";

import "@styles/Page.scss";

export default function FeatEvents() {
  return (
    <PageLayout title="Événements" roles={["admin"]} lang="fr">
      <Section id="les-types-d-événements" title="Les types d'événements">
        <p>
          Les événements dans Dotter permettent de marquer les étapes
          importantes de la participation des patients à l’étude, depuis leur
          inclusion jusqu’à leur sortie du protocole.
          <br />
          Chaque modèle de formulaire peut être associé à un événement (de façon
          facultative).
        </p>
        <img src={eventtypes} alt="Types d'événements" className="centered" />

        <p>
          Les événements pouvant être associés sont :
          <li>
            <b>Inclusion / Exclusion</b> : définit le formulaire permettant de
            compléter les critères d’inclusion, non inclusion et/ou exclusion
            des patients. Si aucune erreur n’est relevée à la complétion du
            formulaire, le patient est directement inclus dans le protocole ; à
            l’inverse, toute valeur non attendue conduira à son exclusion,
            permettant de conserver une trace de l’étape de screening
          </li>
          <li>
            <b>Procotole complété</b> : définit le dernier formulaire de
            l’étude, à l’issue duquel le patient est considéré comme ayant
            terminé toutes les étapes de l’investigation clinique avec succès
          </li>
          <li>
            <b>Événement indésirable</b> : peut être associé à un ou plusieurs
            modèles de formulaires, pour marquer chaque signalement d’un
            événement indésirable grave (ou non) pour les différents patients
            tout au long de l’étude
          </li>
          <li>
            <b>Sortie de l'étude</b> : définit un formulaire à l’issue duquel le
            patient sort prématurément de l’étude, que ce soit pour un retrait
            de consentement, un événement indésirable grave, etc.
          </li>
        </p>
        <h2 id="caractère-unique-des-formulaires-avec-événement">
          Caractère unique des formulaires avec événement
        </h2>
        <p>
          L’association d’un formulaire à un événement le rend « Unique »,
          c’est-à-dire que son état lors de l’enregistrement est{" "}
          <b>définitif</b> et ce dernier ne peut plus être édité par
          l’investigateur.
        </p>
        <img src={eventbell} alt="Cloche des événements" className="centered" />
        <p>
          Les événements associés à une étude peuvent être identifiés facilement
          au sein des Modèles de formulaires grâce à la présence d’une{" "}
          <b>cloche</b>. Lorsque le modèle est associé à une étape dans le
          Synopsis de l’étude, cette dernière hérite également de la cloche.
        </p>
        <h2 id="enregistrement-des-événements-en-live">
          Enregistrement des événements en « Live »
        </h2>
        <p>
          Lorsqu’un formulaire associé à un événement est enregistré par un des
          investigateurs pour un patient donné, l’événement est automatiquement
          enregistré dans la base de données de l’étude. L’ensemble des
          événements peut être retrouvé dans la <b>Liste des événements</b>,
          tableau présent dans l’onglet <b>Live</b> de Dotter.
        </p>
        <img
          src={interfaceevents}
          alt="Liste des événements"
          className="centered"
        />
        <p>
          Il est également possible de notifier par mail certains utilisateurs
          de la survenue d’un type ou de l’intégralité des événements de
          l’étude. Merci de bien vouloir contacter un administrateur pour
          configurer les notifications.
        </p>
      </Section>
    </PageLayout>
  );
}
