import settings from "@content/tutorial/images/Settings-amico.svg";
import ongletadmin from "@content/tutorial/images/en/design-admin.png";
import parametresgeneraux from "@content/tutorial/images/en/general-settings.png";
import centresinvestigateurs from "@content/tutorial/images/en/add-investigation-centers.png";
import ajouterutilisateur from "@content/tutorial/images/en/add-user.png";

import { AtoZLayout } from "@content/tutorial/a-to-z/Layout";

import { Note, Section, SubSection } from "@components";

export default function TutorialAtoZStep2() {
  return (
    <AtoZLayout lang="en" idx={2} heroUrl={settings} heroAlt="Settings">
      <Section title="Configuring Administrative Details">
        <p>
          When you access your study for the first time, we recommend you to
          configure the administrative information as soon as possible. We
          advise you to do so for better traceability of information during data
          exports.
        </p>
        <p>
          To do this, go to the <b>Design</b> tab and then to{" "}
          <b>Administrative</b> to configure your study.
        </p>
        <img src={ongletadmin} alt="Administrative Tab" className="centered" />

        <SubSection title="General Parameters of the Study">
          <p>
            In the "<b>General Parameters</b>" section, fill in at least the
            fields marked with an asterisk (*); these are mandatory for
            progressing in the study design. If you have additional information
            (study acronym, ID RCB number, etc.), you can fill it in now or come
            back to it later.
          </p>
          <img
            src={parametresgeneraux}
            alt="General Parameters Tab"
            className="centered"
          />
          <p>
            The <b>Classification</b> field refers to the type of clinical study
            you are conducting among the following options:
            <li>Interventional Research</li>
            <li>Interventional Research with Minimal Risks and Constraints</li>
            <li>Non-Interventional Research</li>
          </p>
          <p>
            Once you have finished, don’t forget to click "<b>Edit</b>" to save
            your changes.
          </p>
        </SubSection>
        <SubSection title="Add Investigator Centers">
          <p>
            Once the administrative information is completed, you will need to
            create the investigator centers participating in your study.
            Creating these centers will allow you to organize and distribute all
            users and participating patients in the study. In the{" "}
            <b>Investigator Centers</b> block, click the "<b>+</b>" button and
            enter the center's name (mandatory).
          </p>
          <img
            src={centresinvestigateurs}
            alt="Add an Investigator Center"
            className="centered"
          />
          <Note type="note">
            If you wish, you can add:
            <li>
              <b>an acronym</b>: it will be used to identify the center in
              certain lists and forms to simplify display.
            </li>
            <li>
              <b>a reference</b>: it will be used during exports to correlate
              with internal references, for example.
            </li>
          </Note>
          <p>
            Repeat the process for each investigator center associated with the
            study. Once all have been added, you can move on to user management.
          </p>
        </SubSection>
        <SubSection title="Invite Users">
          <p>
            Adding users is by an invitation system only. When you add a user
            via Dotter.science, the person will receive an invitation email to
            confirm their addition to the study. The user must accept the
            invitation to be considered added to the study.
          </p>
          <p>
            In the <b>User Management</b> block, click the "<b>+</b>" button,
            fill in the three mandatory fields provided, and then click{" "}
            <b>Add</b>. If you are unsure about a user's role, you can refer to
            the guide on{" "}
            <a href="/en/guides/roles" target="_blank">
              <b>Roles</b>
            </a>
            .
          </p>
          <img src={ajouterutilisateur} alt="Add a User" className="centered" />
          <Note type="caution">
            The email address must be entered in a <b>valid format</b> and
            preferably for <b>professional use</b>. All fields (email address,
            affiliated center, and role) must be filled in, even if the
            affiliated center does not exist; in that case, select "
            <em>None</em>".
          </Note>
          <Note type="tip">
            If a user has lost the invitation email to the study, you can resend
            it (limited to one send per day) by clicking on the user’s edit
            button and then "<em>Resend invitation email to this user</em>".
          </Note>
        </SubSection>
      </Section>
    </AtoZLayout>
  );
}
