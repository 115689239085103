import setup from "@content/tutorial/images/Setup-amico.svg";
import accesschemadetude from "@content/tutorial/images/fr/acces-schema-etude.png";
import choixrandomisation from "@content/tutorial/images/fr/choix-randomisation-simple.png";
import ajoutergroupe from "@content/tutorial/images/fr/ajouter-groupe.png";
import formatpatient from "@content/tutorial/images/fr/formatpatient.png";

import { Note, Section, SubSection, ResponsiveTable } from "@components";

import { AtoZLayout } from "@content/tutorial/a-to-z/Layout";

export default function TutorialAtoZStep3() {
  return (
    <AtoZLayout lang="fr" idx={3} heroUrl={setup} heroAlt="Configuration">
      <Section title="Configuration de la partie recherche">
        <p>
          Passons maintenant à l'aspect recherche. Nous allons configurer les
          points techniques relatifs à la gestion des patients, leur répartition
          en groupes et leur référencement dans l'étude. Pour cela, rendez-vous
          dans le menu <b>Conception</b> puis sur l'onglet <b>Schéma d'étude</b>
          .
        </p>
        <img
          src={accesschemadetude}
          alt="Onglet schéma d'étude"
          className="centered"
        />
        <SubSection title="Choisir le mode de randomisation">
          <p>
            Dans le bloc <b>Conception de recherche</b>, vous pouvez choisir de
            remplir les trois premiers champs ou de les remplir plus tard. Ici,
            nous allons en priorité nous intéresser à la randomisation. Si vous
            ne souhaitez pas randomiser les patients de votre étude, vous pouvez
            passer cette partie et accéder directement à la gestion des groupes.
          </p>
          <img
            src={choixrandomisation}
            alt="Choix de randomisation"
            className="centered"
          />
          <Note type="tip">
            Le champ "Effectif théorique attendu" permet de fixer un objectif
            d'inclusion. Si le champ indique "0", cet objectif ne sera pas pris
            en compte. S'il est différent, alors les utilisateurs pourront voir
            la progression des inclusions par rapport à cet objectif dans leur
            interface.
          </Note>
          <p>
            Dans le champ <b>Choix d'une randomisation</b>, choisissez le type
            de randomisation qui corresponde à vos besoins. Dotter.science
            propose 3 types de randomisation.
          </p>
          <ResponsiveTable>
            <tr>
              <td>Type de randomisation</td>
              <td>Fonctionnement</td>
            </tr>
            <tr>
              <td>
                Aucune <br />
                (pas de groupe ou attribution manuelle)
              </td>
              <td>
                Si un seul groupe est prévu dans l’étude (pas de randomisation
                dans ce cas), ou si l’attribution au sein des groupes doit se
                faire manuellement (via une boîte de dialogue)
              </td>
            </tr>
            <tr>
              <td>Simple</td>
              <td>
                Les patients sont répartis aléatoirement dans l’ensemble des
                groupes créés (algorithme de randomisation sans équilibrage des
                groupes)
              </td>
            </tr>
            <tr>
              <td>Par blocs (taille fixe)</td>
              <td>
                Les patients sont répartis aléatoirement dans les groupes en
                garantissant l’équilibrage au sein de chaque bloc, dont la
                taille est définie par l’utilisateur
              </td>
            </tr>
            <tr>
              <td>Par blocs (taille variable)</td>
              <td>
                Les patients sont répartis aléatoirement dans les groupes en
                garantissant l’équilibrage au sein de blocs de taille variable
                et non prévisible (en fixant la taille minimale et maximale des
                blocs)
              </td>
            </tr>
          </ResponsiveTable>
          <Note type="note">
            Si plusieurs centres investigateurs participent à l’étude, la
            randomisation est automatiquement équilibrée au sein de chaque
            centre.
          </Note>
        </SubSection>
        <SubSection title="Création des groupes de randomisation">
          <p>
            Une fois le type de randomisation choisi, vous pouvez créer les
            groupes dans lesquels seront répartis les patients randomisés.
          </p>
          <p>
            Dans la partie <b>Gestion des groupes</b> cliquez sur le bouton "
            <b>+</b>", entrez un nom pour le groupe et cliquez sur{" "}
            <b>Ajouter</b>.
          </p>
          <img
            src={ajoutergroupe}
            alt="Ajouter un groupe"
            className="centered"
          />
          <p>
            Les patients pourront être randomisés parmi ces groupes par les
            utilisateurs dès lors qu'ils auront été inclus dans l'étude.
          </p>
          <Note type="tip">
            Pour comprendre toutes les spécificités de la randomisation, vous
            pouvez consulter la documentation avancée en{" "}
            <a href="/fr/advanced/randomization">cliquant ici</a>.
          </Note>
        </SubSection>
        <SubSection title="Définir les références patient">
          <p>
            Au cœur de l'étude et dans les données exportées, les patients
            seront référencés par un format spécifique afin de préserver leur
            anonymat. Dotter.science vous permet de définir le format de cette
            référence. Pour cela, rendez-vous dans le bloc{" "}
            <b>Format des références patient</b>.
          </p>
          <img
            src={formatpatient}
            alt="Format des références patient"
            className="centered"
          />
          <p>
            Les références patients sont découpées en chaîne d'éléments choisis
            parmi :<li>les initiales du patient</li>
            <li>
              le nom du centre dont il fait parti (nom court ou référence)
            </li>
            <li>
              un nombre issu d'un décompte (général à l'étude ou spécifique au
              centre du patient)
            </li>
            <li>
              des caractères aléatoires (en nombre défini) numériques ou
              alphanumériques
            </li>
            <li>des séparateurs (prédéfinis ou personnalisés)</li>
          </p>
          <p>
            Pour <b>ajouter un élément</b>, cliquez sur le bouton <b>+</b> à
            droite de la chaîne et choisissez puis paramétrez celui que vous
            souhaitez.
            <br />
            Pour <b>supprimer un élément</b>, passez votre curseur dessus et
            cliquez sur l'icône de poubelle qui se trouve en-dessous.
            <br />
            Pour <b>déplacer un élément</b>, passez votre curseur dessus et
            cliquez sur les flèches (gauche ou droite) qui apparaîssent
            au-dessus.
          </p>
          <Note type="note">
            Pour insérer un élément en début de chaîne, vous devez d'abord le
            créer en fin de chaîne puis le déplacer avec les flèches.
          </Note>
        </SubSection>
      </Section>
    </AtoZLayout>
  );
}
