import inclusion from "@content/tutorial/images/Completed-amico.svg";
import suivipatients from "@content/tutorial/images/en/patients-list-menu.png";
import nouveaupatient from "@content/tutorial/images/en/add-patient-button.png";
import ajoutpatient from "@content/tutorial/images/en/add-patient-modal.png";
import confirmation from "@content/tutorial/images/en/add-patient-confirmation.png";
import etapeinclusion from "@content/tutorial/images/en/baseline-steps.png";
import popupinclusion from "@content/tutorial/images/en/include-confirmation.png";
import randomize from "@content/advanced/images/en/randomize-button.png";

import { Note, Section } from "@components";

import { UsingDotterLayout } from "@content/tutorial/using-dotter/Layout";

export default function TutorialUsingDotterStep2() {
  return (
    <UsingDotterLayout
      idx={2}
      lang="en"
      heroUrl={inclusion}
      heroAlt="Inclusions"
    >
      <Section title="Add Patients">
        <p>
          Your study is in production, and you want to add your first patients.
          To do this, go to the <b>Live</b> menu and then the{" "}
          <b>Patient Tracking</b> tab.
        </p>
        <img
          src={suivipatients}
          alt="Patient Tracking Tab"
          className="centered"
        />
        <p>
          The list of patients in the current study will be displayed. To add a
          new patient, click on the <b>New Patient</b> button at the bottom of
          the screen.
        </p>
        <img src={nouveaupatient} alt="New Patient" className="centered" />
        <p>
          Now fill in the patient's details and click <b>Add</b> to confirm the
          information.
        </p>
        <img src={ajoutpatient} alt="Add Patient" className="centered" />
        <Note type="note">
          The length of the field "<em>Patient Initials</em>" may be limited
          based on the study's requirements.
          <br />
          Guidelines on the order of initials may also have been configured and
          will appear next to the field if applicable.
        </Note>
        <p>
          A window will confirm the addition of the new patient. You will then
          have the choice to:
          <li>
            click on <b>Go to Inclusion</b> to directly access the patient's
            inclusion form
          </li>
          <li>
            click on <b>Back</b> to return to the patient list
          </li>
        </p>
        <img src={confirmation} alt="Confirmation" className="centered" />
      </Section>
      <Section title="Include a Patient in the Study">
        <p>
          There are two ways to access the inclusion of a patient:
          <li>
            either you clicked on <b>Go to Inclusion</b> after adding a new
            patient
          </li>
          <li>
            or you returned to the patient list, in which case click on the
            patient to include to access their file, then select the step
            containing the inclusion form.
          </li>
        </p>
        <img src={etapeinclusion} alt="Inclusion Step" className="centered" />
        <p>
          The inclusion form will appear. Complete the form with your patient's
          data and validate it by clicking the <b>Save</b> button at the bottom
          of the screen. A window will open indicating that validating this form
          will result in the inclusion or exclusion of the patient from the
          study (depending on the responses in the form). Click <b>Include</b>{" "}
          or <b>Exclude</b> to confirm your choice.
        </p>
        <img
          src={popupinclusion}
          alt="Inclusion Confirmation"
          className="centered"
        />
        <Note type="caution">
          Once a patient is included, you cannot change their inclusion status
          unless an exclusion event occurs during the study.
        </Note>
        <Note type="tip">
          If an inclusion target has been set for your study, a progress bar
          will indicate the advancement towards this target. To view it, access
          the patient list (in the <b>Patient Tracking</b> tab), and it will be
          displayed at the top of the screen.
        </Note>
      </Section>
      <Section title="Randomizing a Patient">
        <p>
          Only an included patient can be randomized. If your patient is not
          included, refer to the previous section. <br /> If the patient is
          already included, go to their file and click on the <b>Randomize</b>{" "}
          button at the bottom of the screen.
        </p>
        <img src={randomize} alt="Randomize" className="centered" />
        <p>
          A different window will appear depending on the chosen{" "}
          <a href="/en/advanced/randomization">
            <b>type of randomization</b>
          </a>{" "}
          for your study:
          <li>
            <em>None or Manual Assignment</em>: a field allows you to choose the
            group you want to assign the patient to, followed by a prompt to
            confirm your choice.
          </li>
          <li>
            Other specific randomization: the distribution is done
            automatically, and a message will indicate the result of the
            randomization.
          </li>
        </p>
      </Section>
    </UsingDotterLayout>
  );
}
