import loginscreen from "@content/guides/images/en/login-screen.png";
import subscribe from "@content/guides/images/en/finalize-subscription.png";
import forgottenpassword from "@content/guides/images/en/lost-password.png";

import { LockOpen } from "@mui/icons-material";

import { PageLayout, Section, SubSection, Note } from "@components";

import "@styles/Page.scss";

export default function GuideLogin() {
  return (
    <PageLayout
      title="Login and Troubleshooting"
      titleIcon={<LockOpen />}
      titleClassName="guide"
      lang="en"
    >
      <Section
        id="connecting-to-dotter-science"
        title="Connecting to Dotter.science"
      >
        <SubSection title="Accessing the Platform">
          <p>
            There are two instances of Dotter.science available online:
            <li>
              "<b>Demo</b>" instance:{" "}
              <a href="https://demo.dotter.science" target="_blank">
                <b>https://demo.dotter.science</b>
              </a>
            </li>
            <li>
              "<b>Official</b>" instance with servers for hosting health data:{" "}
              <a href="https://demo.dotter.science" target="_blank">
                <b>https://hds.dotter.science</b>
              </a>
            </li>
          </p>

          <Note type="important">
            A different account is required to access each instance. Login is
            done using a professional email address and a secure password.
          </Note>
          <img src={loginscreen} alt="Login Screen" className="centered" />
          <p>
            Once on the platform, if <b>you are already registered</b>, simply
            enter your e-mail address (used for registration) and password, then
            click on <b>Login</b>.
          </p>
          <p>
            If <b>you are not registered yet and wish to create a study</b>, you
            can request this by clicking on <b>Registration</b>. A form will
            open for you to submit your request; complete it and click{" "}
            <b>Send</b>.
          </p>
          <p>
            If <b>you are not registered yet and wish to join a study</b>, ask
            the administrator of your study to add you from the{" "}
            <b>Study Settings</b> by providing your email address. You will
            receive an email indicating that you have been invited to join the
            study, along with a link to finalize your registration. Click on it
            and complete the form to confirm your registration.
          </p>
          <img
            src={subscribe}
            alt="Finalize Your Registration"
            className="centered"
          />
        </SubSection>
        <SubSection title="First Login">
          <p>
            Upon your first login, it is recommended to complete the information
            related to your profile (see the guide on{" "}
            <a href="/en/guides/profile">
              <b>profile management</b>
            </a>
            ). Reset your <b>secure password</b> and provide{" "}
            <b>your contact details</b> as well as <b>your affiliations</b> so
            that our teams can assist you as quickly as possible in case of any
            issues.
          </p>
        </SubSection>
      </Section>
      <Section
        id="in-case-of-passsword-forgetfulness"
        title="In Case of Forgotten Password"
      >
        <p>
          If you have forgotten your login password, complete the "
          <em>Email Address</em>" field, and a button "
          <b>I Forgot My Password</b>" will appear below the login form. Click
          on it, and a link will be sent to you via email to reset your password
          (receiving the email may take a few minutes).
        </p>
        <img
          src={forgottenpassword}
          alt="Forgotten Password"
          className="centered"
        />
        <h2 id="in-case-of-email-address-forgetfulness">
          In Case of Forgotten Email Address
        </h2>
        <p>
          If you have forgotten your login email address, please contact your
          study administrator to have it communicated to you again.
        </p>
      </Section>
    </PageLayout>
  );
}
