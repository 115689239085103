import queries from "@content/tutorial/images/Add notes-amico.svg";
import openquery from "@content/advanced/images/en/open-query.png";
import redigerquery from "@content/advanced/images/en/query-modal.png";
import accesqueries from "@content/advanced/images/en/queries-list-menu.png";
import filtres from "@content/tutorial/images/en/queries-filter.png";

import { Note, Section } from "@components";

import { UsingDotterLayout } from "@content/tutorial/using-dotter/Layout";

export default function TutorialUsingDotterStep4() {
  return (
    <UsingDotterLayout idx={4} lang="en" heroUrl={queries} heroAlt="Queries">
      <Section title="Open a Query">
        <p>
          <b>Queries</b> are notes that can be manually added to any entry of
          the eCRF and listed in a table for you to consult later for an
          overview.
        </p>
        <p>
          To open a query, hover over the desired eCRF field; a dialogue bubble
          will then appear to its right. Click on the bubble to open the query
          editing window.
        </p>
        <img src={openquery} alt="Adding a query" className="centered" />
        <p>
          Write your query and then click "<b>Send</b>"
        </p>
        <img src={redigerquery} alt="Writing a query" className="centered" />
      </Section>
      <Section title="Consult Queries">
        <p>
          To get an overview of all the queries in the study, go to the{" "}
          <b>Live</b> menu and then the <b>Queries List</b> tab.
        </p>
        <img src={accesqueries} alt="Queries tab" className="centered" />
        <p>Click on any query to view its content.</p>
        <Note type="tip">
          To facilitate the search for specific queries, you can filter the
          queries by clicking the "<b>Add a filter</b>" button at the top right
          of the screen. You can sort them based on various criteria (status,
          issuing center, date).
          <img src={filtres} alt="Query filters" className="centered" />
        </Note>
      </Section>
    </UsingDotterLayout>
  );
}
