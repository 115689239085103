import setup from "@content/tutorial/images/Setup-amico.svg";
import interfacepatients from "@content/tutorial/images/en/global-patient-list.png";
import interfacepatientsavance from "@content/tutorial/images/en/detailed-patient-list.png";
import interfacedossier from "@content/tutorial/images/en/patient-file-view.png";
import interfaceformulaire from "@content/tutorial/images/en/completing-the-eCRF.png";

import { Note, Section, SubSection, ResponsiveTable } from "@components";

import {
  HelpOutlineOutlined,
  DoneOutlined,
  WarningOutlined,
  Report,
  SignalCellular4Bar,
  SignalCellular2Bar,
  Chat,
  GppGood,
  Lock,
} from "@mui/icons-material";

import { UsingDotterLayout } from "@content/tutorial/using-dotter/Layout";

export default function TutorialUsingDotterStep3() {
  return (
    <UsingDotterLayout
      idx={3}
      lang="en"
      heroUrl={setup}
      heroAlt="Study Progress"
    >
      <Section title="Patient Tracking">
        <SubSection title="The Global View">
          <p>
            The <b>Patient Tracking</b> view (menu → <b>Live</b> tab) allows you
            to see a complete list of all patients in the study, whether they
            are included or not. On this page, you can:
            <li>
              <b>Access a patient's file</b>: by clicking on the corresponding
              row
            </li>
            <li>
              <b>Search for a patient</b> among all study patients: by clicking
              on the magnifying glass at the top of the screen and entering
              their ID
            </li>
            <li>
              <b>Know the overall progress</b> of inclusions: through the
              progress bar at the top of the screen (only displays if a target
              is set)
            </li>
            <li>
              <b>Know the detailed progress</b> of the eCRF for each patient: by
              clicking on the <em>Detailed View</em> button at the top of the
              screen, it then displays the progress of each study phase for each
              patient
            </li>
          </p>
          <img
            src={interfacepatients}
            alt="Patient Tracking Interface"
            className="centered"
          />
          <p>
            To start completing a patient's eCRF, click on the corresponding
            row. If you just created the patient and clicked on{" "}
            <b>Go to Inclusion</b>, you will directly access the patient's eCRF.
          </p>
        </SubSection>
        <SubSection title="The Detailed View">
          <img
            src={interfacepatientsavance}
            alt="Advanced Patient Tracking Interface"
            className="centered"
          />
          <p>
            The detailed view provides an overview of the form completion for
            all patients and by phase. The progress of each phase is represented
            by an icon, with the following meanings:
          </p>
          <ResponsiveTable>
            <tr>
              <td style={{ textAlign: "center" }}>Icon</td>
              <td>Meaning of the Icon</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular4Bar style={{ color: "#B1BABD" }} />
              </td>
              <td>Phase not started</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular2Bar style={{ color: "#3498DB" }} />
              </td>
              <td>Phase started</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular4Bar style={{ color: "#3498DB" }} />
              </td>
              <td>Phase completed</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <Lock style={{ color: "#1ABC9C" }} />
              </td>
              <td>Forms locked</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <GppGood style={{ color: "#27AE60" }} />
              </td>
              <td>Forms signed</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular2Bar style={{ color: "#ffc100" }} /> +{" "}
                <WarningOutlined style={{ color: "#ffc100" }} />
              </td>
              <td>A form contains a warning</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular2Bar style={{ color: "#E74C3C" }} /> +{" "}
                <Report style={{ color: "#E74C3C" }} />
              </td>
              <td>A form contains a critical error</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular2Bar style={{ color: "#3498DB" }} /> +{" "}
                <Chat style={{ color: "#B1BABD" }} />
              </td>
              <td>Queries have been raised</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <SignalCellular4Bar style={{ color: "#DFE3E4" }} />
                <SignalCellular2Bar style={{ color: "#ADD5F0" }} />
                <Lock style={{ color: "#A2E4D7" }} />
                <br />
                <span style={{ fontSize: "14px" }}>+ strikethrough ID</span>
              </td>
              <td>The patient has been excluded from the study</td>
            </tr>
          </ResponsiveTable>
          <Note type="tip">
            You can directly access a phase of the study for a given patient by
            clicking on the corresponding icon.
          </Note>
        </SubSection>
        <SubSection title="Patient File View">
          <img
            src={interfacedossier}
            alt="Patient File Interface"
            className="centered"
          />
          <p>
            On Dotter.science, eCRFs are divided into <b>phases</b> that are
            chronologically spaced. When you are in the patient file, they are
            represented by colored squares (red or blue) and a label indicates
            the occurrence of the phase (
            <em>e.g., "Visit No. 1", "Follow-up at 7 days", "Study Exit"</em>
            ). You can navigate between phases simply by clicking on them.
          </p>
          <p>
            Depending on the study, there may be specific phases:
            <li>
              the <b>Baseline</b> phase is the initial phase of the study. It
              may contain inclusion forms, demographic data, or other legal
              documents (consent, etc.)
              <br />
              <em>
                (Note: if this phase remains empty, it will not appear in the
                study)
              </em>
            </li>
            <li>
              <b>Repeating phases</b>: they display a <b>(+)</b> button that
              allows them to be duplicated (
              <em>
                for example, if a phase can be performed multiple times within
                the same timeframe
              </em>
              )
            </li>
            <li>
              <b>Specific forms</b>, which are not strictly phases, but which,
              in particular cases, contain forms to be filled in outside the
              normal study flow (
              <em>for example, for premature exits from the study</em>)
            </li>
          </p>
          <p>
            Each phase itself contains one or more <b>step(s)</b>, represented
            by green buttons at the top of the screen that contain the form to
            be filled out. To navigate between the steps, click on the button
            that corresponds to it.
          </p>
          <Note type="tip">
            Access additional information in the patient file by clicking on the
            different cards at the top of the page.
          </Note>
        </SubSection>
      </Section>
      <Section title="Completing the eCRF">
        <p>
          Once you have selected a step, fill in the form that appears with your
          study data. Some questions may be disabled by default and will only
          unlock based on the previous response.
        </p>
        <img
          src={interfaceformulaire}
          alt="Form Interface"
          className="centered"
        />
        <p>
          To the right of each field, a visual indicator changes based on the
          data you provided:
        </p>
        <ResponsiveTable>
          <tr>
            <td></td>
            <td>Meaning of the Symbol</td>
          </tr>
          <tr>
            <td>
              <DoneOutlined color="success" />
            </td>
            <td>
              indicates that the field is completed and does not return an error
            </td>
          </tr>
          <tr>
            <td>
              <HelpOutlineOutlined color="disabled" />
            </td>
            <td>indicates that the field is not completed and requires data</td>
          </tr>
          <tr>
            <td>
              <WarningOutlined style={{ color: "#ffc100" }} />
            </td>
            <td>
              indicates that the field might contain an abnormal value (if min
              and max values have been configured for this form)
            </td>
          </tr>
          <tr>
            <td>
              <Report style={{ color: "#E74C3C" }} />
            </td>
            <td>
              indicates that the field contains a value that seems impossible to
              achieve (if min and max values have been configured for this form)
            </td>
          </tr>
        </ResponsiveTable>
        <p>
          Fill in the form with your study data and then click the <b>Save</b>{" "}
          button at the bottom of the screen to validate the form.
        </p>
        <Note type="note">
          If there is an issue with the form (missing data, minor errors, or a
          value that seems impossible to achieve - outlier), a window will
          display to list the alerts, allowing you to <b>return to the form</b>{" "}
          or choose to <b>save anyway</b>.
        </Note>
      </Section>
    </UsingDotterLayout>
  );
}
